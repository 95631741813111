import React, { useState } from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
} from "react-router-dom";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Button,
    Container,
    Row,
    Col,
} from "reactstrap";

import getCulture from "utils/getCulture";
import SimpleHeader from 'components/Headers/SimpleHeader';
import TopNav from './TopNav';
import content from './content';
import Spinner from 'components/Spinner';

const WithInInstitution = () => {

    let rout = useRouteMatch()
    let culture = getCulture(rout.url);
    const [data, setData] = useState([]);
    const [spinner, setSpinner ] = useState(false);
    const [alert, setAlert] = useState(null);
    const [successAlert, setSuccessAlert] = useState('');
    return (
        <div>
            {alert}
            {successAlert}
            {spinner ? <Spinner opacity /> : null}
            <SimpleHeader name={content.title[culture]} parentName={content.parentTitle[culture]} />
            <Container className="mt--6" fluid>
                <Card className="mb-4">
                    <>
                        <CardHeader>
                            <h3 className="mb-0">{content.title[culture]}</h3>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            {content.name[culture]}
                                        </label>
                                        <Input
                                            id="example3cols1Input"
                                            placeholder={content.name[culture]}
                                            type="text"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            {content.serial[culture]}
                                        </label>
                                        <Input
                                            id="example3cols1Input"
                                            placeholder={content.serial[culture]}
                                            type="text"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            {content.machine[culture]}
                                        </label>
                                        <Input
                                            id="example3cols1Input"
                                            placeholder={content.machine[culture]}
                                            type="text"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols3Input"
                                        >
                                            {content.from[culture]}
                                        </label>
                                        <Input
                                            defaultValue={new Date().getFullYear() + "-11-23"}
                                            id="example-date-input"
                                            type="date"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols3Input"
                                        >
                                            {content.to[culture]}
                                        </label>
                                        <Input
                                            defaultValue={new Date().getFullYear() + "-11-23"}
                                            id="example-date-input"
                                            type="date"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md='4'>
                                    <FormGroup>
                                        <label htmlFor="exampleFormControlSelect1">{content.select[culture]}</label>
                                        <Input id="exampleFormControlSelect1" type="select">
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-2 mt-md-0  align-self-center" xs='12'>
                                    <div className="button-wrapper mt-3">
                                        <Link to={`/${culture}-admin/createwithininstitution`}>
                                            <Button className="mr-2" color="primary" size="md">
                                                <span className="btn-inner--icon mr-1">
                                                    <i className="ni ni-fat-add" />
                                                </span>
                                                <span>
                                                    {content.new[culture]}
                                                </span>
                                            </Button>
                                        </Link>
                                        <Button className="btn-neutral mr-2" color="success" size="md">
                                            <span className="btn-inner--icon mr-1 ml-1">
                                                <i className="fab fa-sistrix"></i>
                                            </span>
                                            <span>
                                                {content.search[culture]}
                                            </span>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </>
                </Card>
                <TopNav 
                    content={content} 
                    culture={culture} 
                    setData={setData}
                    data={data}    
                    setAlert={setAlert}
                    setSuccessAlert={setSuccessAlert}
                    setSpinner={setSpinner}
                />
            </Container>
        </div>
    )
}


export default WithInInstitution