/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import { Chart } from "chart.js";
// react plugin used to create charts
import { Line, Bar, Doughnut, Pie } from "react-chartjs-2";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import {
  //BrowserRouter as Router,
  useRouteMatch,
} from "react-router-dom";

// core components
import CardsHeader from "./CustomCardsHeader";

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample5
} from "variables/charts.js";
import getCulture from "utils/getCulture";
import axios from 'axios';
import RequestHandler from 'helper/RequestHandler';
import env from 'env';
import content from "./content";

function Dashboard() {
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState('');
  const [data, setData] = useState([]);
  const [sendMsg, setSendMsg] = useState([]);
  const [approvedMsg, setApprovedMsg] = useState([]);
  const [typeCount, setTypeCount] = useState([]);

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");
  };
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const token = localStorage.getItem(btoa('token'));
  
  const handleMonthlySendMsg = (data, msgType, prop) => {
    const monthly = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    data.forEach(msg => {
      // calc only the specific type
      if (msg.type !== msgType) return;

      // if it's has a value
      if (monthly[Number(msg.month) - 1]) 
        monthly[Number(msg.month) - 1] += Number(msg[prop]);
      else 
        monthly[Number(msg.month) - 1] = Number(msg[prop]);
      
      monthly[Number(msg.month) - 1] = Math.round(monthly[Number(msg.month) - 1] * 100) / 100;
    });
    return monthly;
  }
  
  const handleDonugtChart = (data, prop) => {
    // file type against size or count
    const fileType = {
      Totalmessagecreation: 0,
      TotalmessageSend: 0,
      TotalMessageHasBeenApproved: 0,
    }

    // calc the count for each count
    data.forEach((item) => {
      fileType[item.type] += Math.round(item[prop] * 100) / 100;
    })

    console.log({fileType})
    return fileType;
  }

  const chart1 = {
    ...chartExample3,
    data: {
      labels: content.months[culture],
      datasets: [
        {
          data: sendMsg,
          label: content.created[culture],
        },
      ],
    }
  }

  console.log({chart1})
  const chart2 = {
    ...chartExample3,
    data: {
      labels: content.months[culture],
      datasets: [
        {
          data: approvedMsg,
          label: content.sent[culture],
        },
      ],
    }
  }
  

  const chart3 = {
    ...chartExample5,
    data: {
      labels: [
        content.totalMessageCreation[culture], 
        content.totalMessageSent[culture], 
        content.totalMessageApproved[culture],
      ],
      datasets: [{
        data: [
          typeCount.Totalmessagecreation, 
          typeCount.TotalmessageSend, 
          typeCount.TotalMessageHasBeenApproved
        ],
        backgroundColor: ["#2dce89", "#f5365c", "#11cdef", "#fb6340"]
      }]
    }
  }

  return (
    <>
    {loading ? 
      <RequestHandler 
        fn={async () => { 
            const result = await axios.get(`${env.activeServer()}/Dashboard`, {
                headers: {'Authorization': token}
            })
            if (result.status === 200) {
              console.log(result)
              setData(result.data.data[0]);
              setSendMsg(handleMonthlySendMsg(result.data.data[0].parcents, "TotalmessageSend", "count"))
              setApprovedMsg(handleMonthlySendMsg(result.data.data[0].parcents, "TotalMessageHasBeenApproved", "count"))
              setTypeCount(handleDonugtChart(result.data.data[0].parcents, "count"))
            }
        }} 
        setAlert={setAlert}      
        setLoading={setLoading}
        gate="#29bb64"
        bg="#fff"
      />
      :<>
      <CardsHeader name={content.title[culture]} parentName="" data={data} />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="6">
            <Card>
              <CardHeader>
                <h5 className="h3 mb-0">{content.totalMessageCreation[culture]}</h5>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Line
                    data={chart1.data}
                    options={chart1.options}
                    id="chart-sales"
                    className="chart-canvas"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="6">
            <Card>
              <CardHeader>
                <h5 className="h3 mb-0">{content.totalMessageSent[culture]}</h5>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Bar
                    data={chart2.data}
                    options={chart2.options}
                    className="chart-canvas"
                    id="chart-bars"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="6">
            <Card>
              <CardHeader>
                <h5 className="h3 mb-0">{content.msgCount[culture]}</h5>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Doughnut
                    data={chart3.data}
                    options={chart3.options}
                    className="chart-canvas"
                    id="chart-doughnut"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="6">
            <Card>
              <CardHeader>
                <h5 className="h3 mb-0">{content.msgCount[culture]}</h5>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Pie
                    data={chart3.data}
                    options={chart3.options}
                    className="chart-canvas"
                    id="chart-pie"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      </>}
    </>
  );
}

export default Dashboard;
