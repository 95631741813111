import React, { useState, useEffect, useRef, useCallback } from "react";
// react plugin that creates text editor
// import ReactQuill from "react-quill";
import SimpleHeader from 'components/Headers/SimpleHeader';
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Button,
    Container,
    Row,
    Col,
    ListGroupItem,
    ListGroup,
} from "reactstrap";
import { useRouteMatch, useLocation } from 'react-router-dom';
// javascript plugin that creates nice dropzones for files
// import Dropzone from "dropzone";
import file from '../../assets/img/icons/file2.png';
import getCulture from "utils/getCulture";
import { Editor } from '@tinymce/tinymce-react';
import catchAsync from "helper/catchAsync";
import axios from 'axios';
import env from 'env';
import Spinner from 'components/Spinner';
import RequestHandler from 'helper/RequestHandler';
import content from './content';
import { useDropzone } from 'react-dropzone'
import imgIcon from 'assets/img/dropzone/gallery.png';
import fileIcon from 'assets/img/dropzone/file.png';
import word from 'assets/img/dropzone/word.png';
import powerpoint from 'assets/img/dropzone/powerpoint.png';
import pdf from 'assets/img/dropzone/PDF.svg';
import ReactBSAlert from "react-bootstrap-sweetalert";

// this for text editor
// Dropzone.autoDiscover = false;


function CreateWithinInstitution() {

    let rout = useRouteMatch()
    let culture = getCulture(rout.url);
    const [editor, setEditor] = useState("");
    const [data, setData] = useState([]);
    const [alert, setAlert] = useState();
    const [successAlert, setSuccessAlert] = useState('');
    const [loading, setLoading] = useState(true);
    const [spinner, setSpinner] = useState(false);
    const location = useLocation();
    const { email } = location.state || false;
    const [files, setFiles] = useState([]);
    
    const obj = {
        title: useState(''),
        topics: useState(''),
        decisionNo: useState(0),
        decisionDate: useState(0),
        contentLang: useState(0),
        originality: useState(0),
        machineNumber: useState(0),
        serialNumber: useState(0),
        security: useState(0),
        importance: useState(0),
        branch: useState(["3fa85f64-5717-4562-b3fc-2c963f66afa6", "3fa85f64-5717-4562-b3fc-2c963f66afa7 "]),
        department: useState(["3fa85f64-5717-4562-b3fc-2c963f66afa6", "3fa85f64-5717-4562-b3fc-2c963f66afa7"]),
        division: useState(["3fa85f64-5717-4562-b3fc-2c963f66afa6", "3fa85f64-5717-4562-b3fc-2c963f66afa7"]),
        unit: useState(["3fa85f64-5717-4562-b3fc-2c963f66afa6", "3fa85f64-5717-4562-b3fc-2c963f66afa7"]),
        paperCount: useState(1),
        notes: useState(""),
        email: useState(""),
        description: useState(""),
        attachmentTitle: useState("")
    }

    const setter = (key ,val) => {
        console.log(obj[key])
        obj[key][1](val);
    }

    // const token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxMDU0NTgyOTE0YWY0MTUyY2EyYmQxNSIsImlhdCI6MTYzNjI3MTQwOCwiZXhwIjoxNjQ0MDQ3NDA4fQ.J5SuyBj8Kf_LyBhAEQzdBk_oeHxAIXjixqIvEhoHeF8`;
    const token = localStorage.getItem(btoa('token'));

    const editorRef = useRef(null);
    const handleEditorChange = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
            setEditor(editorRef.current.getContent())
        }
    };

    const onDrop = acceptedFiles => {
        console.log(...acceptedFiles)
        const newFiles = [...files, ...acceptedFiles];
        // newFiles.push(acceptedFiles);
        console.log("fff", newFiles);
        setFiles(newFiles);
    }
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
    
    const checkImage = (file, name)=> {

        const extension = name.substr(name.lastIndexOf('.') - name.length + 1);
        
        if (file.startsWith('image')) 
            return imgIcon;
        else if (extension === 'pdf')
        return pdf;
        else if (extension === 'docx' || extension === 'dot')
            return word;
        else if (extension === 'pptx' || extension === 'ppt' || extension === 'ppsx')
            return powerpoint;
        else
            return fileIcon;
    }

    const handleDelete = index => {
        const newFiles = [...files]
        newFiles.splice(index, 1);
        console.log({newFiles})
        setFiles(newFiles)
    }

    const handleCreate = e => catchAsync(async () => {
      e.preventDefault();
      setSpinner(true);
      const schema = {
        qr: 'String',
        topicId: obj.topics[0],
        title: obj.title[0].trim(),
        decisionNumber: Number(obj.decisionNo[0]),
        decisionDate: obj.decisionDate[0],
        contentLanguage: Number(obj.contentLang[0]),
        originality: Number(obj.originality[0]),
        Security: Number(obj.security[0]),
        importance: Number(obj.importance[0]),
        branchId: obj.branch[0],
        departmentId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        // divisionId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        // unitId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        paperCount: Number(obj.paperCount[0]),
        content: editor.trim(),
        notes: obj.notes[0].trim(),
        machineNumber: Number(obj.machineNumber[0]),
        serialNumber: Number(obj.serialNumber[0]),
        creatorId: "daf4fdbd-f861-4bee-89b4-a4a10c31d743",
        lastEditDate: "2021-09-28",
        createDate: "2021-09-28",
        description: obj.description[0].trim(),
        attachmentTitle: obj.attachmentTitle[0].trim(),
      };

      console.log(schema);
      const result = await axios.post(`${env.activeServer()}/CorrespondencesOutSide`, schema, {
        headers: {'Authorization': token}
      })

      if (result.status === 200) {
        setSuccessAlert(
          <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Success"
          onConfirm={() => setSuccessAlert('')}
          onCancel={() => setSuccessAlert('')}
          confirmBtnBsStyle="info"
          confirmBtnText="Ok"
          btnSize=""
       >
          {result.statusText}
       </ReactBSAlert>
        )
      }
    }, () => {
      setSpinner(false);
    }, setAlert)

    return (
      <div>
          {alert}
          {successAlert}
          {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
          {loading ? 
              <RequestHandler 
                  fn={async () => { 
                      console.log('here')

                      const result = await axios.get(`${env.activeServer()}/CorrespondencesOutSide/Preload`, {
                          headers: {'Authorization': token}
                      })
                      if (result.status === 200) {
                          console.log({result: result.data.data})
                          console.log({'ffff': result})
                          setData(result.data.data);
                      }
                  }} 
                  setAlert={setAlert}      
                  setLoading={setLoading}
                  gate="#29bb64"
                  bg="#fff"
              />:
          <>
          <SimpleHeader name="Form elements" parentName="Forms" />
          <Container className="mt--6" fluid>
            <Form onSubmit={handleCreate}>
              <Card className="mb-4">
                  <Card>
                      <CardHeader>
                          <h3 className="mb-0">{content.page[culture]}</h3>
                      </CardHeader>
                      <CardBody>
                          <Row>
                              <Col md="4">
                                  <FormGroup>
                                      <label
                                          className="form-control-label"
                                          htmlFor="example3cols1Input"
                                      >
                                          {content.title[culture]}
                                      </label>
                                      <Input
                                          placeholder={content.title[culture]}
                                          type="text"
                                          value={obj.title[0]}
                                          onChange={e => setter('title', e.target.value)}
                                          required
                                      />
                                  </FormGroup>
                              </Col>
                              <Col md='4'>
                                  <FormGroup>
                                      <label htmlFor="exampleFormControlSelect1">{content.topics[culture]}</label>
                                      <Input 
                                          required
                                          type="select" 
                                          multiple={false}
                                          defaultValue={true}
                                          onChange={e => setter('topics', e.target.value)}
                                      >
                                        <option disabled value> -- {content.selectOption[culture]} -- </option>
                                        {data.topics ? data.topics.map((topic => (
                                          <option key={topic.id} value={topic.id}>{topic.name}</option>
                                        ))) : null}
                                      </Input>
                                  </FormGroup>
                              </Col>
                              <Col md="4">
                                  <FormGroup>
                                      <label
                                          className="form-control-label"
                                          htmlFor="example3cols1Input"
                                      >
                                          {content.machineNumber[culture]}
                                      </label>
                                      <Input
                                          required
                                          type="number"
                                          min="1"
                                          value={obj.machineNumber[0]}
                                          onChange={e => setter('machineNumber', e.target.value)}
                                      />
                                  </FormGroup>
                              </Col>
                              <Col md="4">
                                  <FormGroup>
                                      <label
                                          className="form-control-label"
                                          htmlFor="example3cols1Input"
                                      >
                                          {content.serialNumber[culture]}
                                      </label>
                                      <Input
                                          required
                                          type="number"
                                          min="1"
                                          value={obj.serialNumber[0]}
                                          onChange={e => setter('serialNumber', e.target.value)}
                                      />
                                  </FormGroup>
                              </Col>
                              <Col md="4">
                                  <FormGroup>
                                      <label
                                          className="form-control-label"
                                          htmlFor="example3cols1Input"
                                      >
                                          {content.decisionNo[culture]}
                                      </label>
                                      <Input
                                          required
                                          type="number"
                                          min="1"
                                          value={obj.decisionNo[0]}
                                          onChange={e => setter('decisionNo', e.target.value)}
                                      />
                                  </FormGroup>
                              </Col>
                              <Col md="4">
                                  <FormGroup>
                                      <label
                                          className="form-control-label"
                                          htmlFor="example3cols1Input"
                                      >
                                          {content.decisionDate[culture]}
                                      </label>
                                      <Input
                                          required
                                          type="date"
                                          onChange={e => setter('decisionDate', e.target.value)}
                                      />
                                  </FormGroup>
                              </Col>
                              <Col md='4'>
                                  <FormGroup>
                                      <label htmlFor="exampleFormControlSelect1">{content.contentLang[culture]}</label>
                                      <Input 
                                          required
                                          type="select"
                                          multiple={false}
                                          defaultValue={true}
                                          onChange={e => setter('contentLang', e.target.value)}
                                      >
                                        <option disabled value> -- {content.selectOption[culture]} -- </option>
                                        {data.languages ? data.languages.map((lang, index) => (
                                          <option key={index} value={index}>{content[lang][culture]}</option>
                                        )) : null}
                                      </Input>
                                  </FormGroup>
                              </Col>
                              <Col md='4'>
                                  <FormGroup>
                                      <label htmlFor="exampleFormControlSelect1">{content.originality[culture]}</label>
                                      <Input
                                          required
                                          type="select"
                                          multiple={false}
                                          defaultValue={true}
                                          onChange={e => setter('originality', e.target.value)}
                                      >
                                        <option disabled value> -- {content.selectOption[culture]} -- </option>
                                        {data.originalitys ? data.originalitys.map((item, index) => (
                                          <option key={index} value={index}>{content[item][culture]}</option>
                                        )) : null}
                                      </Input>
                                  </FormGroup>
                              </Col>
                              <Col md='4'>
                                  <FormGroup>
                                      <label htmlFor="exampleFormControlSelect1">{content.security[culture]}</label>
                                      <Input
                                          required
                                          type="select"
                                          multiple={false}
                                          defaultValue={true}
                                          onChange={e => setter('security', e.target.value)}
                                      >
                                        <option disabled value> -- {content.selectOption[culture]} -- </option>
                                        {data.securitys ? data.securitys.map((item, index) => (
                                          <option key={index} value={index}>{content[item][culture]}</option>
                                        )) : null} 
                                      </Input>
                                  </FormGroup>
                              </Col>
                              <Col md='4'>
                                  <FormGroup>
                                      <label htmlFor="exampleFormControlSelect1">{content.branch[culture]}</label>
                                      <Input 
                                          required
                                          type="select"
                                          multiple={false}
                                          defaultValue={true}
                                          onChange={e => setter('branch', e.target.value)}
                                      >
                                        <option disabled value> -- {content.selectOption[culture]} -- </option>
                                        {data.branches ? data.branches.map((item, index) => (
                                          <option key={`${item.id}-${index}`} value={item.id}>{item.name}</option>
                                        )) : null} 
                                      </Input>
                                  </FormGroup>
                              </Col>
                              <Col md='4'>
                                  <FormGroup>
                                      <label htmlFor="exampleFormControlSelect1">{content.importance[culture]}</label>
                                      <Input 
                                          required
                                          type="select"
                                          multiple={false}
                                          defaultValue={true}
                                          onChange={e => setter('importance', e.target.value)}
                                      >
                                        <option disabled value> -- {content.selectOption[culture]} -- </option>
                                        {data.importances ? data.importances.map((item, index) => (
                                          <option key={index} value={index}>{content[item][culture]}</option>
                                        )) : null} 
                                      </Input>
                                  </FormGroup>
                              </Col>
                              <Col md='4'>
                                  <FormGroup>
                                      <label htmlFor="exampleFormControlSelect1">{content.paperCount[culture]}</label>
                                      <Input 
                                          required
                                          type="number" 
                                          min="1" 
                                          value={obj.paperCount[0]}
                                          onChange={e => setter('paperCount', e.target.value)}
                                      />
                                  </FormGroup>
                              </Col>
                              {email ?
                                  <Col md='4'>
                                      <FormGroup>
                                          <label htmlFor="exampleFormControlSelect1">{content.email[culture]}</label>
                                          <Input 
                                              required
                                              type="email" 
                                              value={obj.email[0]}
                                              placeholder={content.email[culture]}
                                              onChange={e => setter('email', e.target.value)}
                                          />
                                      </FormGroup>
                                  </Col>
                                  :null
                              }
                          </Row>
                          <Row>
                              <label htmlFor="exampleFormControlSelect1">{content.notes[culture]}</label>
                              <Input 
                                  required
                                  type="textarea" 
                                  onChange={e => setter('notes', e.target.value)}
                                  value={obj.notes[0]}
                              ></Input>
                          </Row>
                      </CardBody>
                  </Card>
              </Card>
              <Card>
                  <div className='custom-text-editor'>
                      <Editor
                          required
                          onInit={(evt, editor) => editorRef.current = editor}
                          value={editor}
                          apiKey="443cb0f3z6tpkkzuciio2p0jg3x217eftmvqv05v47uz6ety"
                          init={{
                              height: 500,
                              menubar: false,
                              plugins: [
                                  'advlist autolink lists link image charmap print preview anchor',
                                  'searchreplace visualblocks code fullscreen',
                                  'insertdatetime media table paste code help wordcount'
                              ],
                              toolbar: 'undo redo | formatselect | ' +
                                  'bold italic backcolor | alignleft aligncenter ' +
                                  'alignright alignjustify | bullist numlist outdent indent | ' +
                                  'removeformat | help | forecolor | table',
                              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                              formats: {
                                alignright: { selector : 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video', styles: { 'direction': 'rtl', 'text-align': 'right' }, exact: true }    
                              } 
                          }}
                          onEditorChange={handleEditorChange}
                      />
                  </div>
              </Card>
              <Card>
                  <CardHeader>
                      <h3 className="mb-0">{content.attachements[culture]}</h3>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="6">
                          <FormGroup>
                              <label
                                  className="form-control-label"
                                  htmlFor="example3cols1Input"
                              >
                                  {content.title[culture]}
                              </label>
                              <Input
                                  placeholder={content.title[culture]}
                                  type="text"
                                  value={obj.attachmentTitle[0]}
                                  onChange={e => setter('attachmentTitle', e.target.value)}
                              />
                          </FormGroup>
                      </Col>
                      <Col md="12">
                          <FormGroup>
                              <label
                                  className="form-control-label"
                                  htmlFor="example3cols1Input"
                              >
                                  {content.description[culture]}
                              </label>
                              <Input
                                  placeholder={content.description[culture]}
                                  type="textarea"
                                  value={obj.description[0]}
                                  onChange={e => setter('description', e.target.value)}
                              />
                          </FormGroup>
                      </Col>
                    </Row>
                      <div
                          className="dropzone dropzone-multiple"
                          id="dropzone-multiple"
                      >
                      </div>
                      <div className="dropzone-custom">
                          <div {...getRootProps()}>
                          <input {...getInputProps({
                              multiple: true
                          })} />
                          {
                              isDragActive ?
                              <p className="custom-dropzone-content"><span>{content.drop[culture]}</span></p> :
                              <p className="custom-dropzone-content"><span>{content.drag[culture]}</span></p>
                          }
                      </div>
                      {files.length ? files.map((file, index) => (
                          <div className="dropzone-files" key={index}>
                              <div className="dropzone-content">
                                  <div className="dropzone-icon">
                                      <img src={checkImage(file.type, file.name)}/>
                                  </div>
                                  <div className="dropzone-name">
                                      {file.name}
                                  </div>
                              </div>
                              <div className="dropzone-button">
                                  <button 
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleDelete(index)
                                    }}>
                                      <i className="fas fa-trash-alt"></i>
                                  </button>
                              </div>
                          </div>
                      )): null}
                      </div>
                      <div className='mt-4'>
                          <Button className="btn-icon btn-3" color="primary" type="submit">
                              <span className="btn-inner--text">{content.create[culture]}</span>
                          </Button>
                      </div>
                  </CardBody>
              </Card>
            </Form>
          </Container>
          </>
          } 
      </div>
    );
}

export default CreateWithinInstitution;