export default
	{
		title: {
			ar: "أنواع المراسلات",
			en: "Type of Correspondence"
		},
		parentTitle: {
				ar: "شؤون المراسلات",
				en: "Correspondence matters"
		},
		lead: {
			ar: "هذه الشاشة موجودة لحماية حسابك، في حالة خروجك مؤقتا من النظام او عدم استعماله لفترة من الزمن او الخروج منه، رجائا قم بإدخال كملة المرور مجددا اذا كنت تريد الدخول الى النظام، او الضغط على خيار لست انا ذا كنت تريد الدخول بحساب أخر",
			en: "This screen is there to protect your account, if you are temporarily logged out of the system or not using it for a period of time or exit from it, please re enter youre password if you want to enter the system, or click on the “Not me” option if you want to log in with another account"
		},

		name: {
			ar: "الإسم",
			en: "Name"
		},
		add: {
			ar: 'إضافة',
			en: 'Add'
		},
		search: {
			ar: 'بحث',
			en: 'search'
		},
		ok: {
			ar: 'موافق',
			en: 'ok'
		},
    topics: {
			ar: 'الموضوع',
			en: 'Topics'
		},
		actions: {
			ar: 'العمليات',
			en: 'Actions'
		},
		editTopic: {
			ar: 'تعديل الموضوع',
			en: 'Edit Topic'
		},
		deleteTopic: {
			ar: 'حذف الموضوع',
			en: 'Delete topic'
		},
		deletePopup: {
			ar: 'هل انت متأكد أنك تريد حذف هذا الموضوع ؟',
			en: 'Are you sure you want to delete this topic ?'
		},
		delete: {
			ar: 'حذف',
			en: 'Delete'
		},
		editName: {
			ar: 'تعديل الاسم',
			en: 'Edit name'
		}
	}