import React, { useEffect, useState } from "react";
// react router
import {
   BrowserRouter as Router,
   Switch,
   Route,
   Link,
   useRouteMatch,
   useParams,
} from "react-router-dom";
import getCulture from "utils/getCulture";
// reactstrap components
import {
   Badge,
   Container,
   Input,
   Button,
   DropdownMenu,
   DropdownItem,
   UncontrolledDropdown,
   DropdownToggle,
   Media,
   Progress,
   Table,
   UncontrolledTooltip,
} from "reactstrap";
import ModalPopup from '../components/ModalPopup';
//content 
import content from './content';

function Tables(props) {

   let rout = useRouteMatch()
   let culture = getCulture(rout.url);
   const [editModal, setEditModal] = useState(false);
   const [deleteModal, setDeleteModal] = useState(false);
   const [id, setId] = useState('');
   const [name, setName] = useState('');

   const handleUpdate = () => {
      props.updateTopic(id, name);
      setEditModal(false);
   }

   const handleDelete = () => {
      props.deleteTopic(id)
      setDeleteModal (false);
   }

   return (
      <>
         <Table className="align-items-center" responsive>
            <thead className="thead-light">
               <tr>
                  <th scope="col">{content.topics[culture]}</th>
                  <th scope="col">{content.actions[culture]}</th>
               </tr>
            </thead>
            <tbody>
               {props.data ? props.data.map(el => (
                  <tr className='table-row' key={el.id}>
                     <th scope="row">
                        <Media className="align-items-center">
                           {/* <a
                              className="avatar rounded-circle"
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                           >
                              <img
                                 alt="..."
                                 src={require("assets/img/theme/bootstrap.webp").default}
                              />
                           </a> */}
                           <Media>
                              <span className="mb-0 text-sm mr-2 ml-2">
                                 {el.name}
                              </span>
                           </Media>
                        </Media>
                     </th>
                     <td>
                        <div className="d-flex align-items-center">
                           <span>
                              <div id="edit" className="table-action" onClick={() => {
                                 setId(el.id);
                                 setName(el.name);
                                 setEditModal(!editModal);
                              }}>
                                 <i className="fas fa-edit fa-lg blue-hover"></i>
                              </div>
                              <UncontrolledTooltip
                                 delay={0}
                                 target="edit"
                              >
                                 {content.editTopic[culture]}
                              </UncontrolledTooltip>
                           </span>
                           <span className="mr-2">
                              <div id="delete" className="table-action-delete table-action mr-3 ml-3" 
                                 onClick={() => {
                                    setId(el.id);
                                    setName(el.name);
                                    setDeleteModal(true);
                                    console.log(el);
                                 }}
                              >
                                 <i className="fas fa-trash fa-lg"></i>
                              </div>
                              <UncontrolledTooltip
                                 delay={0}
                                 target="delete"
                              >
                                 {content.deleteTopic[culture]}
                              </UncontrolledTooltip>
                           </span>
                        </div>
                     </td>
                  </tr>

               )) : null}
            </tbody>
         </Table>
         <ModalPopup
            modal={editModal}
            setModal={setEditModal}
            text={content.ok[culture]}
            setId={setId}
            fn={handleUpdate}
         >
            <div className="modal-body">{content.editName[culture]}</div>
            <Container>
               <Input
                  placeholder={content.name[culture]}
                  type="text"
                  onChange={(e) => setName(e.target.value)}
               />
            </Container>
         </ModalPopup>
         <ModalPopup
            modal={deleteModal}
            setModal={setDeleteModal}
            text={content.delete[culture]}
            setId={setId}
            fn={handleDelete}
            color='danger'
         >
            <div className="modal-body">{name}</div>
            <Container>
               <h2>{content.deletePopup[culture]}</h2>
            </Container>
         </ModalPopup>
      </>
   );
}

export default Tables