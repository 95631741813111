import React, { useState } from "react";
// react router
import {
   BrowserRouter as Router,
   Switch,
   Route,
   Link,
   useRouteMatch,
   useParams
} from "react-router-dom";

// reactstrap components
import {
   Badge,
   DropdownMenu,
   DropdownItem,
   UncontrolledDropdown,
   DropdownToggle,
   Media,
   Progress,
   Table,
   Input,
   UncontrolledTooltip,
   Container,
   Row
} from "reactstrap";
import ModalPopup from '../components/ModalPopup';
import gw from 'assets/img/brand/gw.png';

function Tables(props) {

   const [editModal, setEditModal] = useState(false);
   const [deleteModal, setDeleteModal] = useState(false);
   const [margModal, setMargModal] = useState(false);
   const [name, setName] = useState('');
   const [address, setAddress] = useState('');
   const [id, setId] = useState('');
   const [toggle, setToggle] = useState(true);

   const handleUpdate = () => {
      props.updateEmail(id, name, address, toggle);
      setEditModal(false);
      setName('');
      setAddress('');
      setId('');
   }

   const tody = () => {
      const today = new Date();
      const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      return date;
   }

   return (
      <>
         <Table className="align-items-center" responsive>
            <thead className="thead-light">
               <tr>
                  <th scope="col">{props.content.sender[props.culture]}</th>
                  <th scope="col">{props.content.receivedDate[props.culture]}</th>
                  <th scope="col">{props.content.index[props.culture]}</th>
                  <th scope="col">{props.content.serialNumber[props.culture]}</th>
                  <th scope="col">{props.content.senderSide[props.culture]}</th>
                  <th scope="col">{props.content.receivingSide[props.culture]}</th>
                  <th scope="col">{props.content.action[props.culture]}</th>
               </tr>
            </thead>
            <tbody>
               {props.data.map(email => (
                  <tr className='table-row' key={email.id}>
                     <th scope="row">
                        <Media className="align-items-center">
                           {/* <a
                              className="avatar rounded-circle"
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                           >
                              <img
                                 alt="..."
                                 src={"http://localhost:3000/static/media/bootstrap.bd712487.jpg"}
                              />
                           </a> */}
                           <Media>
                              <span className="mb-0 text-sm">
                                 {email.name}
                              </span>
                           </Media>
                        </Media>
                     </th>
                     <td>
                        <span className="badge-dot">
                           {email.address}
                        </span>
                     </td>
                     <td>
                        <Badge color="" className="badge-dot">
                           <i className={
                              email.type === 0 ? "bg-success": "bg-info"
                           } />
                           <span className='type-icon'>
                              {email.type === 0 ? props.content.personal[props.culture] : props.content.company[props.culture]}
                           </span>
                        </Badge>
                     </td>
                     <td>1112</td>
                     <td>2221</td>
                     <td>3333</td>
                     <td>
                        <div className="d-flex align-items-center justify-content-start">
                           <span className="mr-2 mr-3 ml-3">
                              <div id="edit" className="table-action" onClick={() => {
                                 setId(email.id);
                                 setMargModal(!margModal);
                                 setName(email.name);
                                 setToggle(email.type === 0 ? true : false);
                                 setAddress(email.address);
                              }}>
                                 <i className="fas fa-ban fa-lg danger-hover"></i>
                              </div>
                              <UncontrolledTooltip
                                 delay={0}
                                 target="edit"
                              >
                                 {props.content.marginalization[props.culture]}
                              </UncontrolledTooltip>
                           </span>
                        </div>
                     </td>
                  </tr>
               ))}
               <ModalPopup
                  modal={margModal}
                  setModal={setMargModal}
                  text="Approve"
                  setId={setId}
                  fn={handleUpdate}
               >
                  <div className="custom-top-modal d-flex justify-content-between">
                     <div className="index">1122</div>
                     <div className="date">{tody()}</div>
                  </div>
                  <div className="modal-body">
                     <div className="custom-modal-wrapper">
                        <img src={gw} />
                     </div>
                     <div>
                        <p>صورة العلم</p>
                     </div>
                  </div>
               </ModalPopup>
            </tbody>
         </Table>
      </>
   );
}

export default Tables