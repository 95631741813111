/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

import {
  //BrowserRouter as Router,
  useRouteMatch,
} from "react-router-dom";

import getCulture from "utils/getCulture";
import content from "./content";

function CardsHeader({ name, parentName, data }) {

  let rout = useRouteMatch()
  let culture = getCulture(rout.url);

  const checkType = type => {
    if (type.toLowerCase() === "branches") 
      return 'fas fa-building';

    else if (type.toLowerCase() === "externalincominglog")
      return "fas fa-external-link-alt"

    else if (type.toLowerCase() === "internalincominglog")
      return "ni ni-chart-pie-35"

    else return "ni ni-money-coins"
  }

  const color = ['red', 'success', 'warning', 'primary'];

  return (
    <>
      <div className="header bg-primary pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7" className="d-flex align-items-center rtl-right">
                <h6 className="h2 text-white d-block mb-0 mr-2 ml-2">{name}</h6>{" "}
                <Breadcrumb
                  className="d-block ml-lg-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem className="ml-2 mr-2">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col className="text-right" lg="6" xs="5">
                <Button
                  className="btn-neutral"
                  color="default"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  size="sm"
                >
                  New
                </Button>
                <Button
                  className="btn-neutral"
                  color="default"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  size="sm"
                >
                  Filters
                </Button>
              </Col>
            </Row>
            <Row>
              {data.panels.map((item, index) => (
                <Col md="6" xl="3" key={index}>
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            {content[item.type.toLowerCase()][culture]}
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {item.count}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className={`icon icon-shape bg-gradient-${color[index]} text-white rounded-circle shadow`}>
                            <i className={checkType(item.type)} />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        <span className="text-success mr-2">
                          <i className={"fa fa-arrow-up"} /> {item.parcent}%
                        </span>{" "}
                        <span className="text-nowrap">{content.lastMonth[culture]}</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CardsHeader;
