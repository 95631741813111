export default
    {
        title: {
            ar: "إعتماد الطلبات",
            en: "Approval of requests"
        },
        parentTitle: {
            ar: "الصادر",
            en: "Outgoings"
        },
        lead: {
            ar: "هذه الشاشة موجودة لحماية حسابك، في حالة خروجك مؤقتا من النظام او عدم استعماله لفترة من الزمن او الخروج منه، رجائا قم بإدخال كملة المرور مجددا اذا كنت تريد الدخول الى النظام، او الضغط على خيار لست انا ذا كنت تريد الدخول بحساب أخر",
            en: "This screen is there to protect your account, if you are temporarily logged out of the system or not using it for a period of time or exit from it, please re enter youre password if you want to enter the system, or click on the “Not me” option if you want to log in with another account"
        },

        name: {
            ar: "الإسم",
            en: "Name"
        },
        search: {
            ar: 'بحث',
            en: 'search'
        },
        sentWFM: {
            ar: 'تم الارسال وفي انتظار المراسلة',
            en: 'Sent, waiting for message'
        },
        waitingForApprove: {
            ar: 'تم الاسال و بالانتظار المراجعة',
            en: 'Submitted and awaiting review '
        },
        seen: {
            ar: 'تم رؤيتها و بإنتظار الموافقة',
            en: 'Seen and waiting for approve'
        },
        approved: {
            ar: 'تم الاعتماد',
            en: 'Approved'
        },
        approval: {
            ar: 'إعتماد',
            en: 'Approval'
        },
        declined: {
            ar: 'مرفوض',
            en: 'Declined'
        },
        decline: {
            ar: 'رفض',
            en: 'Decline'
        },
        canceled: {
            ar: 'ملغي',
            en: 'Canceled'
        },
        new: {
            ar: 'جديد',
            en: 'New'
        },
        send: {
            ar: 'إرسال',
            en: 'Send'
        },
        eye: {
            ar: 'تمت القراءة',
            en: 'Mark as Read'
        },
        seenPopup: {
            ar: 'هل أنت متأكد أنك تريد وضع علامة "مقروءة" على هذه المراسلة ؟',
            en: 'Are you sure you want to mark this correspondence as read ?'
        },
        approvalPopup: {
            ar: 'هل أنت متأكد أنك تريد الموافقة على هذه المراسلة؟',
            en: 'Are you sure you want to approve this correspondece ?'
        },
        declinePopup: {
            ar: 'هل أنت متأكد أنك تريد رفض هذه المراسلات؟',
            en: 'Are you sure you want to decline this correspondence ?'
        },
        table: {
            project: {
                ar: 'المشروع',
                en: 'project'
            },
            status: {
                ar: 'الحالة',
                en: 'status'
            },
            action: {
                ar: 'العمليات',
                en: 'actions'
            }
        },
        edit: {
            ar: 'تعديل',
            en: 'Edit'
        },
        delete: {
            ar: 'حذف',
            en: 'Delete'
        },
        from: {
            ar: 'من',
            en: 'from'
        },
        to: {
            ar: 'إلى',
            en: 'To'
        },
        select: {
            ar: 'إختيار',
            en: 'Select'
        },
        serial: {
            ar: 'الرقم التسلسلي',
            en: 'Serial Number'
        },
        machine: {
            ar: 'رقم الآلة',
            en: 'Machine Number'
        },
        date: {
            ar: 'التاريخ',
            en: 'Date'
        },
        waitingApproval: {
            ar: 'في انتظار الاعتماد',
            en: 'Waiting Approval'
        },
        resent: {
            ar: 'تم إعادة الإرسال',
            en: 'Resent'
        },
        sendername: {
            ar: 'اسم المرسل',
            en: 'Sender name'
        },  
        statementTitle: {
            ar: 'عنوان البيان',
            en: 'Statement title'
        },
        skip: {
            ar: 'تخطي',
            en: 'Skip'
        },
        resend: {
            ar: 'إعادة الارسال',
            en: 'Resend'
        },
        forwordPopup: {
            ar: 'هل أنت متأكد أنك تريد الموافقة وارسال هذه المراسلة ؟',
            en: 'Are you sure you want to accept and send this message ?'
        },
        ok: {
            ar: 'موافق',
            en: 'OK'
        },
        creator: {
            ar: 'المنشئ',
            en: 'Creator'
        },
        grid: [
            {
                img: "http://localhost:3000/static/media/bootstrap.bd712487.jpg",
                name: "english name",
                serial: "N48JD8 9DUF JFD1 KSN3",
                machine: "8349FJ 43R 34R"
            },
            {
                img: "http://localhost:3000/static/media/bootstrap.bd712487.jpg",
                name: "بالعربي",
                serial: "N48JD8 9DUF JFD1 KSN3",
                machine: "8349FJ 43R 34R"
            },
            {
                img: "http://localhost:3000/static/media/bootstrap.bd712487.jpg",
                name: "مخلط Eee",
                serial: "N48JD8 9DUF JFD1 KSN3",
                machine: "8349FJ 43R 34R"
            },
        ]
    }