export default {
    title: {
        ar: 'البريد الإلكتروني الخارجي',
        en: 'External Email'
    },
    parentTitle: {
        ar: "شؤون المراسلات",
        en: "Correspondence matters"
    },
    name: {
        ar: "الإسم",
        en: "Name"
    },
    search: {
        ar: 'بحث',
        en: 'search'
    },
    email: {
        ar: 'البريد الإلكتروني',
        en: 'email'
    },
    add: {
        ar: 'إضافة',
        en: 'Add'
    },
    type: {
        ar: 'النوع',
        en: 'Types'
    },
    types: [
        {
            ar: 'شخصي',
            en: 'Personal'
        },
        {
            ar: 'جهة',
            en: 'Institution'
        }
    ],
    action: {
        ar: 'العمليات',
        en: 'Actions'
    },
    edit: {
        ar: 'تعديل',
        en: 'edit'
    },
    delete: {
        ar: 'حذف',
        en: 'delete'
    },
    personal: {
        ar: 'شخصي',
        en: "Personal"
    },
    company: {
        ar: 'جهة',
        en: 'Company'
    },
    address: {
        ar: 'مكان الجهة',
        en: 'Address'
    },
    editPopup: {
        ar: "تعديل بيانات البريدالالكتروني",
        en: "Update email data"
    },
    ok: {
        ar: 'موافق',
        en: 'Ok'
    },
    deletePopup: {
        ar: 'هل انت متأكد من انك تريد حذف هذة المراسلة ؟',
        en:'Are you sure you want to delete this email ?'
    },
    tables: [
        {
            img: 'http://localhost:3000/static/media/bootstrap.bd712487.jpg',
            name: 'sender name',
            email: 'anastaleb@gmail.com',
            type: {
                ar: 'شخصي',
                en: 'personal'
            },
            typeLogo: 'bg-success'
        },
        {
            img: 'http://localhost:3000/static/media/bootstrap.bd712487.jpg',
            name: 'اسم عربي',
            email: 'anasTT@gmail.com',
            type: {
                ar: 'جهة',
                en: 'Institution'
            },
            typeLogo: 'bg-info'
        },
    ]
}