const Url = "https://mail.gw.ly/api/v1";
const farhat = "http://192.168.0.107/GW.Mail.WebServerApi/api/v1";
const Origin = "http://localhost:3000/";
const frontUrl = "http://localhost:3000";
const frontNetlify = "https://gw-mail.netlify.app";
const local = `http://localhost:5000/api/v1`;
const archiveHeroku = `https://gw-archive.herokuapp.com/api/v1`;
const archiveLS = `https://archive.ggw.ly/api/v1`;
const front = "https://identity-fr.ggw.ly";

// exports.Url = () => Url;
// exports.farhat = () => farhat;
// exports.Origin = () => Origin;
exports.activeServer = () => Url;
exports.frontUrl = () => front;
exports.identity = () => `https://id.ggw.ly/api/v1`;
// exports.identity = () => `https://identety.herokuapp.com/api/v1`;
exports.archive = () => archiveLS;
exports.Origin = () => Origin;
// http://192.168.0.106/GW.Mail.WebServerApi/api/v1

exports.state = [
  { ar: "تم الانشاء", en: "Created" },
  { ar: "مسودة", en: "Draft" },
  { ar: "في انتظار المراجعة", en: "Waiting Review" },
  { ar: "في انتظار الموافقة", en: "Waiting Approval" },
  { ar: "تم اعادة ارسال", en: "Redirected" },
  { ar: "تمت الموافقة", en: "Approved" },
  { ar: "تم الرفض", en: "Declined" },
  { ar: "تم الالغاء", en: "Canceled" },
  { ar: "تمت الارشفة", en: "Archived" },
  { ar: "تمت القراءة", en: "Reading" },
  { ar: "تم الغاء اعادة الارسال", en: "Declained Reditect" },
  { ar: "تم الارسال", en: "Send" },
];
