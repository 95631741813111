export default {
    page: {
        ar: "الوارد الخارجي",
        en: "External Received"
    },
    receivedDate: {
        ar: 'تاريخ الاستلام',
        en: 'Received Date'
    },
    index: {
        ar: 'الرقم الاشاري',
        en: "Index"
    },
    serialNumber: {
        ar: 'الرقم التسلسلي',
        en: 'Serial Number'
    },   
    sender: {
        ar: 'الجهة المرسلة',
        en: 'Sender'
    },
    receiver: {
        ar: 'الجهة المستلمة',
        en: 'Receiver'
    },
    attachmentCount: {
        ar: 'عدد المرفقات',
        en: 'Attachment Count'
    },
    messageSubject: {
        ar: 'موضوع المراسلة',
        en: 'Message Subject'
    },
    create: {
        ar: 'إنشاء',
        en: 'Create'
    },
    supplierName: {
        ar: 'اسم المورد',
        en: 'Supplier Name'
    }
}