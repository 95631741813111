import React, { useEffect, useState } from "react";
// react router
import {
   BrowserRouter as Router,
   Switch,
   Route,
   Link,
   useRouteMatch,
   useParams
} from "react-router-dom";

// reactstrap components
import {
   Badge,
   DropdownMenu,
   DropdownItem,
   UncontrolledDropdown,
   DropdownToggle,
   Media,
   Progress,
   Table,
   UncontrolledTooltip,
   Container
} from "reactstrap";
import getCulture from "utils/getCulture";
import Spinner from "components/Spinner";
import ModalPopup from "Screen/components/ModalPopup";
import content from './content';
import catchAsync from "helper/catchAsync";
import axios from 'axios';
import RequestHanlder from 'helper/RequestHandler';
import env from 'env';
import ReactBSAlert from "react-bootstrap-sweetalert";
function Tables(props) {

   let rout = useRouteMatch()
   let culture = getCulture(rout.url);
   const [id, setId] = useState('');
   const [name, setName] = useState('');
   const [loading, setLoading] = useState(true);
   const [seenModal, setSeenModal] = useState(false);
   const [approveModal, setApproveModal] = useState(false);
   const [declineModal, setDeclineModal] = useState(false);
   const [forwordModal, setForwordModal] = useState(false);
   const [isOpen, setIsOpen] = useState(true);

   // const token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxMDU0NTgyOTE0YWY0MTUyY2EyYmQxNSIsImlhdCI6MTYzNjI3MTQwOCwiZXhwIjoxNjQ0MDQ3NDA4fQ.J5SuyBj8Kf_LyBhAEQzdBk_oeHxAIXjixqIvEhoHeF8`;
   const token = localStorage.getItem(btoa('token'));

   const handleRequest = (url, setModal) => catchAsync(async () => {
      setIsOpen(false)
      props.setSpinner(true);
      setModal(false);
      const result = await axios.put(`${env.activeServer()}/correspondencesRequest/${url}/${id}`, {}, {
         headers: { "Authorization": "Bearer " + token }
      })
      console.log({result})
      if (result.status === 200) {
         
         if (url !== "Read") {
            // splice the row from the data
            const newData = [...props.data].filter(item => item.id != id);
            props.setData(newData);
         } else {

            // get all data
            const newData = [...props.data]
            
            // get row index
            let rowIndex;
            [...props.data].forEach((item, index) => {
               if (item.id == id) {
                  console.log('here', index)
                  rowIndex = index;
               }
            })
            console.log({rowIndex})
            // change state to 9
            // 9 means that it's been read
            newData[rowIndex].state = 9;
            console.log('props', props.data);
            console.log({newData})
            // set the new data
            props.setData([...newData])
            setIsOpen(true)
         }

         // show alert
         props.setSuccessAlert(
            <ReactBSAlert
               success
               style={{ display: "block", marginTop: "-100px" }}
               title="Success"
               onConfirm={() => props.setSuccessAlert('')}
               onCancel={() => props.setSuccessAlert('')}
               confirmBtnBsStyle="info"
               confirmBtnText="Ok"
               btnSize=""
            >
               {result.statusText}
            </ReactBSAlert>
         );
      }
   }, () => {
      setId('')
      props.setSpinner(false);
   }, props.setAlert)
   

   return (
      <>
         {
            loading ? <RequestHanlder fn={async () => {
              // apiUrl prop comes from TopNav
               const result = await axios.get(`${env.activeServer()}/${props.apiUrl}`, {
                  headers: { "Authorization": "Bearer " + token }
               })
                if(result.status) {
                  console.log("bbbbbbb", result.data.data)
                  props.setData(result.data.data.reverse());
                  // window.setTimeout(() => {
                  //    setToolTip(true);
                  // }, 0)
                }
              }}
                setLoading={setLoading}
                setAlert={props.setAlert}
                gate="#29bb64"
                bg="#fff"
              /> :
         <>
            <Table className="align-items-center" responsive>
               <thead className="thead-light">
                  <tr>
                     <th scope="col">{content.creator[culture]}</th>
                     <th scope="col">{content.sendername[culture]}</th>
                     <th scope="col">{content.statementTitle[culture]}</th>
                     <th scope="col">{content.serial[culture]}</th>
                     <th scope="col">{content.machine[culture]}</th>
                     <th scope="col">{content.date[culture]}</th>
                     {props.actions ? <th scope="col">{content.table.action[culture]}</th> : null}
                  </tr>
               </thead>
               <tbody>
                  {
                     props.data.map((item, index) => (
                        <tr key={item.id}>
                           <th scope="row">
                              <Media className="align-items-center">
                                 <Media>
                                    <span className="mb-0 text-sm">
                                       <Link 
                                          to={{
                                             pathname: `/${culture}-admin/movementgraph/${item.id}`, 
                                             admin: true
                                             
                                          }}
                                       >
                                          {item.creatorName}
                                       </Link>
                                    </span>
                                 </Media>
                              </Media>
                           </th>
                           <td>
                              <span>{item.senderName}</span>
                           </td>
                           <td>
                              <span>{item.title}</span>
                           </td>
                           <td>
                              <span>{item.serialNumber}</span>
                           </td>
                           <td>
                              <span>{item.machineNumber}</span>
                           </td>
                           <td>
                              <span>{new Date().getFullYear() + '-10' + '-1'}</span>
                           </td>
                           {props.actions ? 
                           <td>
                              <div className="d-flex align-items-center justify-content-between">
                              {item.state !== 9 ? 
                                    <span className="">
                                       <Link
                                          to='/'
                                          id={`eye${item.id}`} 
                                          className="table-action"
                                          onClick={e => {
                                             e.preventDefault();
                                             setSeenModal(true);
                                             setId(item.id);
                                             setName(item.title)
                                          }}   
                                       >
                                          <i className="far fa-eye fa-lg blue-hover"></i>
                                       </Link>
                                       {isOpen ?
                                       <UncontrolledTooltip
                                          delay={100}
                                          target={`eye${item.id}`}
                                       >
                                          {content.eye[culture]}
                                       </UncontrolledTooltip>
                                       : null }
                                    </span>
                                 : <>
                                 <span className="">
                                    <Link 
                                       to='/'
                                       id={`approval${item.id}`} 
                                       className="table-action"
                                       onClick={e => {
                                          e.preventDefault();
                                          setApproveModal(true)
                                          setId(item.id);
                                          setName(item.title)
                                       }} 
                                    >
                                       <i className="fas fa-check-circle fa-lg success-hover"></i>
                                    </Link>
                                    { isOpen ?
                                    <UncontrolledTooltip
                                       delay={100}
                                       target={`approval${item.id}`}
                                    >
                                       {content.approval[culture]}
                                    </UncontrolledTooltip>
                                    : null }
                                 </span>
                                 <span className="">
                                    <Link 
                                       to='/'
                                       id={`resend${item.id}`} 
                                       className="table-action"
                                       onClick={e => {
                                          e.preventDefault();
                                          setForwordModal(true)
                                          setId(item.id);
                                          setName(item.title)
                                       }} 
                                    >
                                       <i className="fas fa-share fa-lg blue-hover"></i>
                                    </Link>
                                    { isOpen ?
                                    <UncontrolledTooltip
                                       delay={100}
                                       target={`resend${item.id}`}
                                    >
                                       {content.resend[culture]}
                                    </UncontrolledTooltip>
                                    :null }
                                 </span>
                                 <span className="mr-2">
                                    <Link
                                       to='/'
                                       id={`decline${item.id}`} 
                                       className="table-action-delete table-action"
                                       onClick={e => {
                                          e.preventDefault();
                                          setDeclineModal(true);
                                          setId(item.id);
                                          setName(item.title)
                                       }}   
                                    >
                                       {/* <i className="fas fa-trash-alt fa-lg"></i> */}
                                       <i className="fas fa-times-circle fa-lg"></i>
                                    </Link>
                                    { isOpen ?
                                    <UncontrolledTooltip
                                       delay={100}
                                       target={`decline${item.id}`}
                                    >
                                       {content.decline[culture]}
                                    </UncontrolledTooltip>
                                    : null }
                                 </span>
                                 </>}
                              </div>
                           </td>
                           : null }
                        </tr>
                     ))
                  }
                  <ModalPopup
                     modal={seenModal}
                     setModal={setSeenModal}
                     text={content.eye[culture]}
                     setId={setId}
                     fn={() => handleRequest('Read', setSeenModal)}
                     color='primary'
                     name={name}
                  >
                     <Container>
                        <h4 className="modal-body">{content.seenPopup[culture]}</h4>
                     </Container>
                  </ModalPopup>
                  <ModalPopup
                     modal={approveModal}
                     setModal={setApproveModal}
                     text={content.approval[culture]}
                     name={name}
                     setId={setId}
                     fn={() => handleRequest('Approve', setApproveModal)}
                     color='success'
                  >
                     <Container>
                        <h4 className="modal-body">{content.approvalPopup[culture]}</h4>
                     </Container>
                  </ModalPopup>
                  <ModalPopup
                     modal={declineModal}
                     setModal={setDeclineModal}
                     text={content.decline[culture]}
                     name={name}
                     setId={setId}
                     fn={() => handleRequest('Decline', setDeclineModal)}
                     color='danger'
                  >
                     <Container>
                        <h4 className="modal-body">{content.declinePopup[culture]}</h4>
                     </Container>
                  </ModalPopup>
                  <ModalPopup
                     modal={forwordModal}
                     setModal={setForwordModal}
                     text={content.ok[culture]}
                     name={name}
                     setId={setId}
                     fn={() => handleRequest('redirect', setForwordModal)}
                     color='primary'
                  >
                     <Container>
                        <h4 className="modal-body">{content.forwordPopup[culture]}</h4>
                     </Container>
                  </ModalPopup>
               </tbody>
            </Table>
         </>}
      </>
   );
}

export default Tables