import React from "react";
import classnames from "classnames";
// reactstrap components
import {
    Card,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane
} from "reactstrap";
import Tables from './Tables';

class TopNav extends React.Component {
    state = {
        tabs: 1
    };
    toggleNavs = (e, state, index) => {
        e.preventDefault();
        this.setState({
            [state]: index
        });
    };

    render() {
        return (
            <>
                <div className="nav-wrapper">
                    <Nav
                        className="nav-fill flex-column flex-md-row"
                        id="tabs-icons-text"
                        pills
                        role="tablist"
                    >
                        <NavItem>
                            <NavLink
                                aria-selected={this.state.tabs === 1}
                                className={classnames("mb-sm-3 mb-md-0", {
                                    active: this.state.tabs === 1
                                })}
                                onClick={e => this.toggleNavs(e, "tabs", 1)}
                                href="#pablo"
                                role="tab"
                            >
                                <i className="ni ni-cloud-upload-96 mr-2" />
                                {this.props.content.waitingApproval[this.props.culture]}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                aria-selected={this.state.tabs === 2}
                                className={classnames("mb-sm-3 mb-md-0", {
                                    active: this.state.tabs === 2
                                })}
                                onClick={e => this.toggleNavs(e, "tabs", 2)}
                                href="#pablo"
                                role="tab"
                            >
                                <i className="ni ni-calendar-grid-58 mr-2" />
                                {this.props.content.resent[this.props.culture]}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                aria-selected={this.state.tabs === 3}
                                className={classnames("mb-sm-3 mb-md-0", {
                                    active: this.state.tabs === 3
                                })}
                                onClick={e => this.toggleNavs(e, "tabs", 3)}
                                href="#pablo"
                                role="tab"
                            >
                                <i className="ni ni-calendar-grid-58 mr-2" />
                                {this.props.content.approved[this.props.culture]}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                aria-selected={this.state.tabs === 4}
                                className={classnames("mb-sm-3 mb-md-0", {
                                    active: this.state.tabs === 4
                                })}
                                onClick={e => this.toggleNavs(e, "tabs", 4)}
                                href="#pablo"
                                role="tab"
                            >
                                <i className="ni ni-calendar-grid-58 mr-2" />
                                {this.props.content.declined[this.props.culture]}
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <Card className="shadow">
                    <CardBody className='no-padding'>
                        <TabContent activeTab={"tabs" + this.state.tabs}>
                            <TabPane tabId="tabs1">
                                {this.state.tabs === 1 ? <Tables
                                    setAlert={this.props.setAlert}
                                    setSuccessAlert={this.props.setSuccessAlert}
                                    setSpinner={this.props.setSpinner}
                                    setData={this.props.setData}
                                    data={this.props.data}
                                    apiUrl={'CorrespondencesRequest/ViewWaiting'}
                                    actions
                                /> : null}
                                
                            </TabPane>
                            <TabPane tabId="tabs2">
                                {this.state.tabs === 2 ? <Tables
                                    setAlert={this.props.setAlert}
                                    setSuccessAlert={this.props.setSuccessAlert}
                                    setSpinner={this.props.setSpinner}
                                    setData={this.props.setData}
                                    data={this.props.data}
                                    apiUrl={'CorrespondencesRequest/viewRedirect'}
                                    waitingActions
                                /> : null}

                            </TabPane>
                            <TabPane tabId="tabs3">
                                {this.state.tabs === 3? <Tables
                                    setAlert={this.props.setAlert}
                                    setSuccessAlert={this.props.setSuccessAlert}
                                    setSpinner={this.props.setSpinner}
                                    setData={this.props.setData}
                                    data={this.props.data}
                                    apiUrl={'CorrespondencesRequest/viewapprove'}
                                /> : null}

                            </TabPane>
                            <TabPane tabId="tabs4">
                                {this.state.tabs === 4 ? <Tables
                                    setAlert={this.props.setAlert}
                                    setSuccessAlert={this.props.setSuccessAlert}
                                    setSpinner={this.props.setSpinner}
                                    setData={this.props.setData}
                                    data={this.props.data}
                                    apiUrl={'CorrespondencesRequest/ViewDecline'}
                                /> : null}

                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </>
        );
    }
}

export default TopNav;