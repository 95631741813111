import React, { useState, useEffect } from 'react';
import SimpleHeader from 'components/Headers/SimpleHeader';
import {
    Button,
    Container,
    Card,
    Row,
    Form,
    FormGroup,
    Input,
    Col,
    CardBody,
    Media,
    UncontrolledCarousel,
    CardHeader
} from 'reactstrap';
//get user language
import getCulture from "utils/getCulture";
// react router
import { useRouteMatch } from "react-router-dom";
//content
import content from '../MovementsInside/content';

const MovementsGraph = () => {

    const [animation, setAnimation] = useState(false);
    let rout = useRouteMatch();
    let culture = getCulture(rout.url);

    // save prev element in this global varibale
    const positionElements = list => {

        const type = 1, //circle type - 1 whole, 0.5 half, 0.25 quarter
            radius = '20em', //distance from center
            start = -90, //shift start from 0
            elements = list,
            numberOfElements = (type === 1) ? elements.length : elements.length - 1, //adj for even distro of elements when not full circle
            slice = 360 * type / numberOfElements;
        let global;

        const newEl = elements.map((el, index) => {
            // let self = el,
            let rotate = slice * index + start,
                rotateReverse = rotate * -1;

            global = <li className={`child-circle ${el.class}`} key={index} style={animation ? { 'transform': 'rotate(' + rotate + 'deg) translate(' + radius + ') rotate(' + rotateReverse + 'deg)' } : null}>{el.title[culture]}</li>
            return global;
        });
        return (
            <ul className='parent-circle'>
                {newEl}
            </ul>
        )
    }

    useEffect(() => {
        setAnimation(true);
    }, [])

    return (
        <div className='movements'>
            <SimpleHeader name="Form elements" parentName="Forms" />
            <Container className="mt--6" fluid>
                <Card className="mb-4">
                    <CardHeader>
                        <h1>fff</h1>
                    </CardHeader>
                    <CardBody>
                        <div className='movements-circle'>
                            {positionElements(content.list)}
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </div>
    )
}

export default MovementsGraph;