export default {
    title: {
        ar: "حركات الوارد الداخلي",
        en: "Movements inside the device"
    },
    parentTitle: {
        ar: 'الحركات',
        en: 'Movements'
    },
    lead: {
        ar: "هذه الشاشة موجودة لحماية حسابك، في حالة خروجك مؤقتا من النظام او عدم استعماله لفترة من الزمن او الخروج منه، رجائا قم بإدخال كملة المرور مجددا اذا كنت تريد الدخول الى النظام، او الضغط على خيار لست انا ذا كنت تريد الدخول بحساب أخر",
        en: "This screen is there to protect your account, if you are temporarily logged out of the system or not using it for a period of time or exit from it, please re enter youre password if you want to enter the system, or click on the “Not me” option if you want to log in with another account"
    },

    name: {
        ar: "الإسم",
        en: "Name"
    },
    search: {
        ar: 'بحث',
        en: 'search'
    },
    all: {
        ar: 'الكل',
        en: 'All'
    },
    waitingForApprove: {
        ar: 'تم الاسال و بالانتظار المراجعة',
        en: 'Submitted and awaiting review '
    },
    seen: {
        ar: 'تم رؤيتها و بإنتظار الموافقة',
        en: 'Seen and waiting for approve'
    },
    approved: {
        ar: 'تم الاعتماد',
        en: 'Approved'
    },
    declined: {
        ar: 'مرفوض',
        en: 'Declined'
    },
    canceled: {
        ar: 'ملغي',
        en: 'Canceled'
    },
    new: {
        ar: 'جديد',
        en: 'New'
    },
    table: {
        project: {
            ar: 'المشروع',
            en: 'project'
        },
        status: {
            ar: 'الحالة',
            en: 'status'
        },
        action: {
            ar: 'العمليات',
            en: 'actions'
        }
    },
    edit: {
        ar: 'تعديل',
        en: 'Edit'
    },
    delete: {
        ar: 'حذف',
        en: 'Delete'
    },
    from: {
        ar: 'من',
        en: 'from'
    },
    to: {
        ar: 'إلى',
        en: 'To'
    },
    select: {
        ar: 'إختيار',
        en: 'Select'
    },
    serial: {
        ar: 'الرقم التسلسلي',
        en: 'Serial Number'
    },
    machine: {
        ar: 'رقم الآلة',
        en: 'Machine Number'
    },
    date: {
        ar: 'التاريخ',
        en: 'Date'
    },
    grid: [
        {
            img: "http://localhost:3000/static/media/bootstrap.bd712487.jpg",
            name: "english name",
            serial: "N48JD8 9DUF JFD1 KSN3",
            machine: "8349FJ 43R 34R"
        },
        {
            img: "http://localhost:3000/static/media/bootstrap.bd712487.jpg",
            name: "بالعربي",
            serial: "N48JD8 9DUF JFD1 KSN3",
            machine: "8349FJ 43R 34R"
        },
        {
            img: "http://localhost:3000/static/media/bootstrap.bd712487.jpg",
            name: "مخلط Eee",
            serial: "N48JD8 9DUF JFD1 KSN3",
            machine: "8349FJ 43R 34R"
        },
    ],
    list: [
        {
            title: {
                ar: 'معلق',
                en: 'Draft',
            },
            class: 'shortcut-media avatar rounded-circle bg-gradient-orange'
        },
        {
            title: {
                ar: 'تم الانشاء',
                en: 'Created',
            },
            class: 'shortcut-media avatar rounded-circle bg-gradient-info'
        },
        {
            title: {
                ar: 'في انتظار المراجعة',
                en: 'Waiting for review',
            },
            class: 'shortcut-media avatar rounded-circle bg-gradient-green'
        },
        {
            title: {
                ar: '',
                en: 'Waiting for Approve',
            },
            class: 'shortcut-media avatar rounded-circle bg-gradient-purple'
        },
        {
            title: {
                ar: '',
                en: 'Approved',
            },
            class: 'shortcut-media avatar rounded-circle bg-gradient-yello'
        },
        {
            title: {
                ar: '',
                en: 'Canceled',
            },
            class: 'shortcut-media avatar rounded-circle bg-gradient-danger'
        },
        {
            title: {
                ar: '',
                en: 'Archived',
            },
            class: 'shortcut-media avatar rounded-circle bg-gradient-green'
        },
        {
            title: {
                ar: '',
                en: 'Archived',
            },
            class: 'shortcut-media avatar rounded-circle bg-gradient-green'
        },
        {
            title: {
                ar: '',
                en: 'Archived',
            },
            class: 'shortcut-media avatar rounded-circle bg-gradient-green'
        },
        {
            title: {
                ar: '',
                en: 'Archived',
            },
            class: 'shortcut-media avatar rounded-circle bg-gradient-green'
        },
        {
            title: {
                ar: '',
                en: 'Archived',
            },
            class: 'shortcut-media avatar rounded-circle bg-gradient-green'
        },
        {
            title: {
                ar: '',
                en: 'Archived',
            },
            class: 'shortcut-media avatar rounded-circle bg-gradient-green'
        },
        {
            title: {
                ar: '',
                en: 'Archived',
            },
            class: 'shortcut-media avatar rounded-circle bg-gradient-green'
        },
        {
            title: {
                ar: '',
                en: 'Archived',
            },
            class: 'shortcut-media avatar rounded-circle bg-gradient-green'
        },
        {
            title: {
                ar: '',
                en: 'Archived',
            },
            class: 'shortcut-media avatar rounded-circle bg-gradient-green'
        },
        {
            title: {
                ar: '',
                en: 'Archived',
            },
            class: 'shortcut-media avatar rounded-circle bg-gradient-green'
        },
    ],
    sender: {
        ar: 'المرسل',
        en: 'Sender'
    },
    receivedDate: {
        ar: 'تاريخ الاستلام',
        en: 'Received Date'
    },
    index: {
        ar: 'الرقم الاشاري',
        en: "Index"
    },
    serialNumber: {
        ar: 'الرقم التسلسلي',
        en: 'Serial Number'
    },   
    senderSide: {
        ar: 'الجهة المرسلة',
        en: 'Sender Side'
    },    
    receivingSide: {
        ar: 'الجهة المستلمة',
        en: 'Receiving Side'
    },
    attachmentCount: {
        ar: 'عدد المرفقات',
        en: 'Attachment Count'
    },
    messageSubject: {
        ar: 'موضوع المراسلة',
        en: 'Message Subject'
    },
    name: {
        ar: "الإسم",
        en: "Name"
    },
    search: {
        ar: 'بحث',
        en: 'search'
    },
    email: {
        ar: 'البريد الإلكتروني',
        en: 'email'
    },
    statementTitle: {
        ar: 'عنوان البيان',
        en: 'Statement title'
    },
    creator: {
        ar: 'المنشئ',
        en: 'Creator'
    },
    sender: {
        ar: 'المرسل',
        en: 'Sender'
    },
    machineNumber: {
        ar: 'الرقم الآلي',
        en: 'Machine Number'
    },
    date: {
        ar: 'التاريخ',
        en: 'Date'
    },
    add: {
        ar: 'إضافة',
        en: 'Add'
    },
    type: {
        ar: 'النوع',
        en: 'Types'
    },
    from: {
        ar: 'من',
        en: 'From'
    },
    to: {
        ar: 'إلى',
        en: 'To'
    },
    index: {
        ar: 'الرقم الإشاري',
        en: 'Index'
    },
    types: [
        {
            ar: 'شخصي',
            en: 'Personal'
        },
        {
            ar: 'جهة',
            en: 'Institution'
        }
    ],
    action: {
        ar: 'العمليات',
        en: 'Actions'
    },
    edit: {
        ar: 'تعديل',
        en: 'edit'
    },
    delete: {
        ar: 'حذف',
        en: 'delete'
    },
    personal: {
        ar: 'شخصي',
        en: "Personal"
    },
    company: {
        ar: 'جهة',
        en: 'Company'
    },
    new: {
        ar: 'جديد',
        en: 'New'
    },
    tables: [
        {
            img: 'http://localhost:3000/static/media/bootstrap.bd712487.jpg',
            name: 'sender name',
            email: 'anastaleb@gmail.com',
            type: {
                ar: 'شخصي',
                en: 'personal'
            },
            typeLogo: 'bg-success'
        },
        {
            img: 'http://localhost:3000/static/media/bootstrap.bd712487.jpg',
            name: 'اسم عربي',
            email: 'anasTT@gmail.com',
            type: {
                ar: 'جهة',
                en: 'Institution'
            },
            typeLogo: 'bg-info'
        },
    ]
}