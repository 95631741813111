/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";


// router 
import {
    Link,
    useRouteMatch,
    useHistory
  } from "react-router-dom";

  // get culture from utils 
import getCulture from "utils/getCulture";

// core components
import AuthHeader from "components/Headers/AuthHeader";

// content 
import content from "./content";
import catchAsync from "helper/catchAsync";
import axios from "axios";
import env from 'env';
import Spinner from "components/Spinner";

function Register() {
  const [focusedName, setfocusedName] = useState(false);
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [focusedCPassword, setfocusedCPassword] = useState(false);
  const [showPassword, setShowpassword] = useState(false);
  const [showConfermPassword, setConfermShowpassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const history = useHistory();
  let rout = useRouteMatch()
  let culture = getCulture(rout.url) ;

  const obj = {
    username: useState(''),
    email: useState(''),
    password: useState(''),
    confirmPassword: useState('')
  }

  const setter = (key, val) => {
    obj[key][1](val);
  }

  const signup = e => catchAsync(async () => {
    setLoading(true)
    e.preventDefault()
    const schema = {
      username: obj.username[0].trim(),
      email: obj.email[0].trim(),
      password: obj.password[0].trim(),
      confirmPassword: obj.confirmPassword[0].trim()
    }

    const result = await axios.post(`${env.identity()}api/v1/auth/signup`, schema);
    if (result.status === 201) {
      localStorage.setItem(btoa('token'), result.data.token);
      for (const prop in result.data.user) {
        console.log(prop, result.data.user[prop])
        localStorage.setItem(btoa(prop), btoa(result.data.user[prop]))
      }
      history.replace(`/${culture}-admin/dashboard`)
    }
  }, () => {setLoading(false)}, setAlert)

  useEffect(() => {
    if (localStorage.getItem(btoa('token'))) {
      history.replace(`/${culture}-admin/dashboard`)
    }
    return () => {}
  }, [])

  return (
    <>
      {loading ? <Spinner opacity /> : null}
      {alert}
     <div  style={{ marginBottom: "-6.5%" }} />
      <AuthHeader
        title={content.title[culture]}
        lead={content.lead[culture]}
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className="bg-secondary border-0">
            <CardHeader className="bg-transparent pb-5">
                <div className="text-muted text-center mt-2 mb-3">
                  <small style={{ fontSize: "20px"} }>{content.signupTitle[culture]}</small>
                </div>
                <div  style={{ marginTop: "20px" }} className="btn-wrapper text-center">
                  {/* <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        src={
                          require("assets/img/icons/common/github.svg").default
                        }
                      />
                    </span>
                    <span className="btn-inner--text">Github</span>
                  </Button> */}
                  {
                    culture == "ar" ? 
                    <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  > 
                  <span style={{color: "#000"} }className="btn-inner--text">Gateway Ly</span>
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        style={{ width: 50}}
                        src={
                          require("assets/img/brand/gw.png").default
                        }
                      />
                    </span>
                   
                  </Button> 
                  :
                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  > 
                  
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        style={{ width: 50}}
                        src={
                          require("assets/img/brand/gw.png").default
                        }
                      />
                    </span>
                    <span style={{color: "#000"} }className="btn-inner--text">Gateway Ly</span>
                   
                  </Button>

                  }

                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>{content.plsFill[culture]}</small>
                </div>
                <Form role="form" onSubmit={signup} autoComplete="off">
                <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedName,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative rtl">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        value={obj.username[0]}
                        placeholder={content.userName[culture]}
                        onFocus={() => setfocusedName(true)}
                        onBlur={() => setfocusedName(false)}
                        onChange={e => setter('username', e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={content.email[culture]}
                        type="email"
                        minLength="5"
                        value={obj.email[0]}
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(false)}
                        onChange={e => setter('email', e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={content.password[culture]}
                        type={ showPassword ? "text" : "password"}
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(false)}
                        value={obj.password[0]}
                        onChange={e => setter('password', e.target.value)}
                        minLength="8"
                      />
                      <Button color="secondary" outline type="button" onClick={() => setShowpassword(!showPassword)} >
                      <i className={ showPassword ? "fas fa-eye" : "fas fa-eye-slash" }></i>
                      </Button>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedCPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={content.confermPassword[culture]}
                        type={ showConfermPassword ? "text" : "password"}
                        onFocus={() => setfocusedCPassword(true)}
                        onBlur={() => setfocusedCPassword(false)}
                        onChange={e => setter('confirmPassword', e.target.value)}
                        minLength="8"
                      />
                      <Button color="secondary" outline type="button" onClick={() => setConfermShowpassword(!showConfermPassword)} >
                      <i className={ showConfermPassword ? "fas fa-eye" : "fas fa-eye-slash" }></i>
                      </Button>
                    </InputGroup>
                  </FormGroup>
                  {/* <div className="text-muted font-italic">
                    <small>
                      password strength:{" "}
                      <span className="text-success font-weight-700">
                        strong
                      </span>
                    </small>
                  </div> */}
                  {/* <Row className="my-4">
                    <Col xs="12">
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="custom-control-input"
                          id="customCheckRegister"
                          type="checkbox"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheckRegister"
                        >
                          <span className="text-muted">
                            I agree with the{" "}
                            <a
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Privacy Policy
                            </a>
                          </span>
                        </label>
                      </div>
                    </Col>
                  </Row> */}
                  <div className="text-center">
                    <Button className="mt-4" color="success" type="submit">
                      {content.create[culture]}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">

              <Col className="text-center" xs="6">
                <Link style={{ color: "#BFC8D0"}} to={ culture == "ar" ? "/ar-auth/login" : "/en-auth/login"} ><small>{content.login[culture]}</small> </Link>
              </Col>              

              <Col className="text-center" xs="6">
               { culture == "ar" ?
                <Link style={{ color: "#2DCE89"}} to="/en-auth/register" >English</Link>
                :
                <Link style={{ color: "#2DCE89"}} to="/ar-auth/register" >العربية	&nbsp; 	&nbsp;</Link>


                
              }
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Register;