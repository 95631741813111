import React, { useState } from 'react';

// reactstrap components
import {
   Card,
   CardHeader,
   CardBody,
   CardTitle,
   Label,
   FormGroup,
   Form,
   Input,
   Button,
   Container,
   Row,
   Col,
} from "reactstrap";

import ReactBSAlert from "react-bootstrap-sweetalert";
import getCulture from "utils/getCulture";
import { useRouteMatch, Link } from 'react-router-dom';
import SimpleHeader from 'components/Headers/SimpleHeader';
import Tables from './Tables';
import content from './content';
import axios from 'axios';
import RequestHanlder from 'helper/RequestHandler';
import catchAsync from 'helper/catchAsync';
import env from 'env';
import Spinner from 'components/Spinner';

const ExternalReceived = () => {

   let rout = useRouteMatch();
   let culture = getCulture(rout.url);
   const [alert, setAlert] = useState('');
   const [loading, setLoading] = useState(true);
   const [data, setData] = useState([]);
   const [name, setName] = useState('');
   const [id, setId] = useState('');
   const [spinner, setSpinner ] = useState(false);
   const [successAlert, setSuccessAlert] = useState('');
   const [branchId, setBranchId] = useState('');
   const [employeeId, setEmployeeId] = useState('');
   const [branch, setBranch] = useState('')

   const token = localStorage.getItem(btoa('token'));
   // let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxMDU0NTgyOTE0YWY0MTUyY2EyYmQxNSIsImlhdCI6MTYzNjI3MTQwOCwiZXhwIjoxNjQ0MDQ3NDA4fQ.J5SuyBj8Kf_LyBhAEQzdBk_oeHxAIXjixqIvEhoHeF8";
   
   const handleCreate = e => catchAsync(async () => {
      e.preventDefault();
      setSpinner(true);
      const schema = {
         branchId: branch.branchId,
         employeeId,
      }

      const result = await axios.post(`${env.activeServer()}/MessagingSettings`, schema, {
         headers: { "Authorization": "Bearer " + token }
      })
      console.log("resulttt", result)
      if (result.status === 200) {
         setSuccessAlert(
            <ReactBSAlert
               success
               style={{ display: "block", marginTop: "-100px" }}
               title="Success"
               onConfirm={() => { setSuccessAlert(''); setLoading(true) }}
               onCancel={() => { setSuccessAlert(''); setLoading(true) }}
               confirmBtnBsStyle="info"
               confirmBtnText="Ok"
               btnSize=""
            >
            </ReactBSAlert>
         );
      }
   }, () => { 
      setSpinner(false);
      setId('')
      setName('');
   }, setAlert)

   const handleDelete = (id, setDeleteModal) => catchAsync(async () => {
      setDeleteModal(false);
      setSpinner(true);
      const result = await axios.delete(`${env.activeServer()}/MessagingSettings/${id}`, {
         headers: { "Authorization": "Bearer " + token }
      })
      console.log("resulttt", result)
      if (result.status === 200) {
         const newData = data.data.filter(obj => obj.id !== id);
         setData(newData);
         setSuccessAlert(
            <ReactBSAlert
               success
               style={{ display: "block", marginTop: "-100px" }}
               title="Info"
               onConfirm={() => setSuccessAlert('')}
               onCancel={() => setSuccessAlert('')}
               confirmBtnBsStyle="info"
               confirmBtnText="Ok"
               btnSize=""
            >
               A few words about this sweet alert ...
            </ReactBSAlert>
         );
      }
   }, () => { 
      setSpinner(false);
      setId('')
      setName('');
   }, setAlert)

   const handleChange = id => catchAsync(async () => {
      setSpinner(true);
      setEmployeeId(id);
      const result = await axios.get(`${env.activeServer()}/messagingsettings/selectemployee/${id}`, {
         headers: {'Authorization': token}
       })
       if (result.status === 200) {
         console.log({result})
         setBranch(result.data.data)
       }
   }, () => {
      setSpinner(false);
   }, setAlert)

   return (
      <div>
      {successAlert}
      {alert}
      {spinner ? <Spinner opacity /> : null}
      {
         loading ? <RequestHanlder fn={async () => {
            console.log('here')
            const result = await axios.get(`${env.activeServer()}/MessagingSettings`, {
               headers: { "Authorization": "Bearer " + token }
            })
            if (result.status === 200) {
               console.log({result})
               setData(result.data.data[0]);
            }
         }}
            setLoading={setLoading}
            setAlert={setAlert}
            gate="#29bb64"
            bg="#fff"
         /> 
      : <div>
         <SimpleHeader name={content.title[culture]} parentName={content.parentTitle[culture]} />
         <Container className="mt--6" fluid>
            <Card className="mb-4">
               <form>
                  <CardHeader>
                     <h3 className="mb-0">{content.title[culture]}</h3>
                  </CardHeader>
                  <CardBody>
                     <Row>
                        <Col md="5">
                           <FormGroup>
                              <label htmlFor="exampleFormControlSelect1">{content.employees[culture]}</label>
                              <Input 
                                 required
                                 type="select" 
                                 multiple={false}
                                 defaultValue={true}
                                 onChange={e => handleChange(e.target.value)}
                              >
                                 <option disabled value> -- {content.selectOption[culture]} -- </option>
                                 {data.listEmployees.map((employee => (
                                 <option key={employee.id} value={employee.id}>{employee.name}</option>
                                 )))}
                              </Input>
                           </FormGroup>
                        </Col>
                        <Col md="5">
                           <FormGroup>
                              <label htmlFor="exampleFormControlSelect1">{content.branches[culture]}</label>
                              <Input 
                                 required
                                 type="text" 
                                 disabled={true}
                                 value={branch.branchName || ''}
                              />
                           </FormGroup>
                        </Col>
                        <Col className="mt-2 mt-md-0  align-self-center" xs="12">
                           <Button className="mr-2" color="primary" size="md" onClick={handleCreate}>
                              <span className="btn-inner--icon mr-1">
                                 <i className="ni ni-fat-add" />
                              </span>
                              <span>
                                 {content.add[culture]}
                              </span>
                           </Button>
                        </Col>
                     </Row>
                  </CardBody>
               </form>
            </Card>
            <Card>
               <Tables 
                  culture={culture} 
                  content={content} 
                  data={data.data} 
                  setData={setData} 
                  setId={setId}
                  setName={setName}
                  handleDelete={handleDelete}
               />
            </Card>
         </Container>
      </div>}  
      </div>
   )
}


export default ExternalReceived