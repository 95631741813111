import React, { useState } from 'react';

// reactstrap components
import {
   Card,
   CardHeader,
   CardBody,
   CardTitle,
   Label,
   FormGroup,
   Form,
   Input,
   Button,
   Container,
   Row,
   Col,
} from "reactstrap";

import ReactBSAlert from "react-bootstrap-sweetalert";
import getCulture from "utils/getCulture";
import { useRouteMatch } from 'react-router-dom';
import SimpleHeader from 'components/Headers/SimpleHeader';
import Tables from './Tables';
import content from './content';
import axios from 'axios';
import RequestHanlder from 'helper/RequestHandler';
import catchAsync from 'helper/catchAsync';
import env from 'env';
import Spinner from 'components/Spinner';

const ExternalEmail = () => {

   let rout = useRouteMatch();
   let culture = getCulture(rout.url);
   const [toggle, setToggle] = useState(true);
   const [alert, setAlert] = useState('');
   const [loading, setLoading] = useState(true);
   const [data, setData] = useState([]);
   const [name, setName] = useState('');
   const [address, setAddress] = useState('');
   const [id, setId] = useState('');
   const [spinner, setSpinner ] = useState(false);
   const [successAlert, setSuccessAlert] = useState('');

   const token = localStorage.getItem(btoa('token'));
   
   const isDisabled = () => (!name | !address) ? true : false

   const createEmail = () => catchAsync(async () => {
      setSpinner(true);
      const type = toggle ? 0 : 1;
      const schema = { name, address, type };
      console.log(schema)
      const result = await axios.post(`${env.activeServer()}/emails`, schema, {
         headers: { "Authorization": "Bearer " + token }
      })

      if (result.status === 200) {

         setData([
            ...data,
            result.data.data
         ])
         setSuccessAlert(
            <ReactBSAlert
               success
               style={{ display: "block", marginTop: "-100px" }}
               title="Success"
               onConfirm={() => setSuccessAlert('')}
               onCancel={() => setSuccessAlert('')}
               confirmBtnBsStyle="info"
               confirmBtnText="Ok"
               btnSize=""
            >
               {result.statusText}
            </ReactBSAlert>
         );
      }
   }, () => {
      setId('')
      setName('');
      setAddress('');
      setSpinner(false);
   }, setAlert)

   const deleteEmail = (setModal) => catchAsync(async () => {
      setModal(false);
      setSpinner(true);
      const result = await axios.delete(`${env.activeServer()}/emails/${id}`, {
         headers: { "Authorization": "Bearer " + token }
      })
      console.log("resulttt", result)
      if (result.status === 200) {
         const newData = data.filter(obj => obj.id !== id);
         setData(newData);
         setSuccessAlert(
            <ReactBSAlert
               success
               style={{ display: "block", marginTop: "-100px" }}
               title="Info"
               onConfirm={() => setSuccessAlert('')}
               onCancel={() => setSuccessAlert('')}
               confirmBtnBsStyle="info"
               confirmBtnText="Ok"
               btnSize=""
            >
               A few words about this sweet alert ...
            </ReactBSAlert>
         );
      }
   }, () => { 
      setSpinner(false);
      setId('')
      setName('');
      setAddress('');
   }, setAlert)

   const updateEmail = (id, name, address, toggle) => catchAsync(async () => {
      setSpinner(true);
      console.log(0, {data})
      console.log({id, name, address, toggle})
      const sendData = [...data];
      const schema = sendData.find(el => el.id === id);

      schema.name = name || schema.name
      schema.address = address || schema.address
      schema.type = toggle ? 0 : 1;

      const result = await axios.put(`${env.activeServer()}/emails/${id}`, schema, {
         headers: { "Authorization": "Bearer " + token }
      })

      
      console.log(result, "ffff")
      if (result.status === 200) {
         
         console.log('heere', data)
         
         const newData = [...data];
         console.log('ffff', newData);

         newData.forEach(el => {
            if (el.id === id) {
               console.log({id})
               el.name = result.data.name || el.name;
               el.address = result.data.address || el.address;
               el.type = result.data.type || el.type;
               el.id = id;
            }
         })
         setData(newData);
         setSuccessAlert(
            <ReactBSAlert
               success
               style={{ display: "block", marginTop: "-100px" }}
               title="Success"
               onConfirm={() => setSuccessAlert('')}
               onCancel={() => setSuccessAlert('')}
               confirmBtnBsStyle="info"
               confirmBtnText="Ok"
               btnSize=""
            >
               {result.statusText}
            </ReactBSAlert>
         );
      }
      console.log(result);
   }, () => { 
      setSpinner(false)
      setId('')
      setName('');
      setAddress('');
   }, setAlert)
   console.log('parent')

   return (
      <div>
      {successAlert}
      {alert}
      {spinner ? <Spinner opacity /> : null}
      {
         loading ? <RequestHanlder fn={async () => {
            console.log('here')
            const reqData = await axios.get(`${env.activeServer()}/emails`, { 
               headers: { "Authorization": "Bearer " + token }
            })
            if (reqData.status === 200) {
               console.log(reqData.data.data, 'ffff')
               setData(reqData.data.data);
            }
         }}
            setLoading={setLoading}
            setAlert={setAlert}
            gate="#29bb64"
            bg="#fff"
         /> 
      : <div>
         <SimpleHeader name={content.title[culture]} parentName={content.parentTitle[culture]} />
         <Container className="mt--6" fluid>
            <Card className="mb-4">
               <form onSubmit={e => e.preventDefault()}>
                  <CardHeader>
                     <h3 className="mb-0">{content.title[culture]}</h3>
                  </CardHeader>
                  <CardBody>
                     <Row>
                        <Col md="5">
                           <FormGroup>
                              <label
                                 className="form-control-label"
                                 htmlFor="example3cols1Input"
                              >
                                 {content.email[culture]}
                              </label>
                              <Input
                                 id="example3cols1Input"
                                 placeholder={content.email[culture]}
                                 type="text"
                                 value={name}
                                 onChange={(e) => setName(e.target.value)}
                              />
                           </FormGroup>
                        </Col>
                        <Col md="5">
                           <FormGroup>
                              <label
                                 className="form-control-label"
                                 htmlFor="example3cols1Input"
                              >
                                 {content.address[culture]}
                              </label>
                              <Input
                                 id="example3cols1Input"
                                 placeholder={content.address[culture]}
                                 type="text"
                                 value={address}
                                 onChange={(e) => setAddress(e.target.value)}
                              />
                           </FormGroup>
                        </Col>
                        <Col sm='2'>
                           <label
                              className="form-control-label type-input mr-0-4"
                              htmlFor="typeInput"
                           >
                              {content.type[culture]}
                           </label>
                           <label className="custom-toggle mb-2">
                              <input 
                                 defaultChecked type="checkbox" 
                                 onChange={() => setToggle(!toggle)} id="typeInput" 
                                 value={toggle}
                              />
                              <span
                                 className="custom-toggle-slider rounded-circle"
                                 data-label-off={content.types[1][culture]}
                                 data-label-on={content.types[0][culture]}
                              />
                           </label>
                        </Col>
                        <Col className="mt-2 mt-md-0  align-self-center" xs="12">
                           <Button 
                              className="mr-2" 
                              color="primary" 
                              size="md" 
                              disabled={isDisabled()}
                              onClick={createEmail}
                           >
                              <span className="btn-inner--icon mr-1">
                                 <i className="ni ni-fat-add" />
                              </span>
                              <span>
                                 {content.add[culture]}
                              </span>
                           </Button>
                           <Button className="btn-neutral mr-2" color="success" size="md">
                              <span className="btn-inner--icon mr-1 ml-1">
                                 <i className="fab fa-sistrix"></i>
                              </span>
                              <span>
                                 {content.search[culture]}
                              </span>
                           </Button>
                        </Col>
                     </Row>
                  </CardBody>
               </form>
            </Card>
            <Card>
               <Tables 
                  culture={culture} 
                  content={content} 
                  data={data} 
                  setData={setData} 
                  setId={setId}
                  setName={setName}
                  updateEmail={updateEmail}
                  deleteEmail={deleteEmail}
                  setAddress={setAddress}
                  toggle={toggle}
                  setToggle={setToggle}
               />
            </Card>
         </Container>
      </div>}  
      </div>
   )
}


export default ExternalEmail