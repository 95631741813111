import React, { useState, useEffect } from 'react';
/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// router 
import {
   BrowserRouter as Router,
   Switch,
   Route,
   Link,
   useRouteMatch,
   useParams
} from "react-router-dom";

// get culture from utils 
import getCulture from "utils/getCulture";

// reactstrap components
import {
   Card,
   CardHeader,
   CardBody,
   Label,
   FormGroup,
   Form,
   Input,
   Button,
   Container,
   Row,
   Col,
} from "reactstrap";
//content 
import content from './content';
// core components
import SimpleHeader from 'components/Headers/SimpleHeader';
import Tables from './Tables';
import axios from 'axios';
import RequestHanlder from 'helper/RequestHandler';
import catchAsync from 'helper/catchAsync';
import env from 'env';
import Spinner from 'components/Spinner';
import ReactBSAlert from "react-bootstrap-sweetalert";

function Correspondence() {

   let rout = useRouteMatch()
   let culture = getCulture(rout.url);
   const [loading, setLoading] = useState(true);
   const [alert, setAlert] = useState(null);
   const [data, setData] = useState(null);
   const [name, setName] = useState('');
   const [id, setId] = useState('');
   const [spinner, setSpinner ] = useState(false);
   const [successAlert, setSuccessAlert] = useState('');

   // let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxMDU0NTgyOTE0YWY0MTUyY2EyYmQxNSIsImlhdCI6MTYzNjI3MTQwOCwiZXhwIjoxNjQ0MDQ3NDA4fQ.J5SuyBj8Kf_LyBhAEQzdBk_oeHxAIXjixqIvEhoHeF8";
   const token = localStorage.getItem(btoa('token'));

   const createTopic = () => catchAsync(async () => {
      console.log('clicked');
      setSpinner(true)
      const schema = { name };
      const result = await axios.post(`${env.activeServer()}/topics`, schema, {
         headers: { "Authorization": "Bearer " + token }
      })
      console.log("resulttt", result)
      if (result.status === 200) {
         setData([
            result.data.data,
            ...data
         ])
         setSuccessAlert(
            <ReactBSAlert
               success
               style={{ display: "block", marginTop: "-100px" }}
               title="Success"
               onConfirm={() => setSuccessAlert('')}
               onCancel={() => setSuccessAlert('')}
               confirmBtnBsStyle="info"
               confirmBtnText="Ok"
               btnSize=""
            >
               {result.statusText}
            </ReactBSAlert>
         );
      }
   }, () => { 
      setSpinner(false)
      setId(null)
      setName("")
   }, setAlert)

   const updateTopic = (id, name) => catchAsync(async () => {
      setSpinner(true);
      const schema = {
         name
      };
      const result = await axios.put(`${env.activeServer()}/topics/${id}`, schema, {
         headers: { "Authorization": "Bearer " + token }
      })
      if (result.status === 200) {
         const newData = [...data];
         newData.forEach(el => {
            if (el.id === id) {
               el.name = name;
            }
         })
         setData(newData);
         console.log('new', data)   
         setSuccessAlert(
            <ReactBSAlert
               success
               style={{ display: "block", marginTop: "-100px" }}
               title="Success"
               onConfirm={() => setSuccessAlert('')}
               onCancel={() => setSuccessAlert('')}
               confirmBtnBsStyle="info"
               confirmBtnText="Ok"
               btnSize=""
            >
               {result.statusText}
            </ReactBSAlert>
         );
      }
      console.log(result);
   }, () => { 
      setSpinner(false);
      setId(null);
      setName("");
   }, setAlert)

   const deleteTopic = id => catchAsync(async () => {
      setSpinner(true);
      const result = await axios.delete(`${env.activeServer()}/topics/${id}`, {
         headers: { "Authorization": "Bearer " + token }
      })
      console.log("resulttt", result)
      if (result.status === 200) {
         const newData = data.filter(obj => obj.id !== id);
         setData(newData);
      }
   }, () => { 
      setSpinner(false);
      setId(null);
      setName("");
   }, setAlert)


   return (
      <>
         {alert}
         {successAlert}
         {spinner ? <Spinner opacity /> : null}
         {
            loading ? <RequestHanlder fn={async () => {
               const reqData = await axios.get(`${env.activeServer()}/Topics`, {
                  headers: { "Authorization": "Bearer " + token }
               })
               if(reqData.status) {
                  setData(reqData.data.data.reverse());
               }
            }}
               setLoading={setLoading}
               setAlert={setAlert}
               gate="#29bb64"
               bg="#fff"
            /> :
               <>
                  <SimpleHeader name={content.title[culture]} parentName={content.parentTitle[culture]} />
                  <Container className="mt--6" fluid>
                     <Card className="mb-4">
                        <CardHeader>
                           <h3 className="mb-0">{content.title[culture]}</h3>
                        </CardHeader>
                        <CardBody>
                           <Row>
                              <Col md="6" lg='6'>
                                 <FormGroup>
                                    <label
                                       className="form-control-label"
                                       htmlFor="example3cols1Input"
                                    >
                                       {content.name[culture]}
                                    </label>
                                    <Input
                                       id="example3cols1Input"
                                       placeholder={content.name[culture]}
                                       onChange={(e) => setName(e.target.value)}
                                       value={name}
                                       type="text"
                                    />
                                 </FormGroup>
                              </Col>
                              <Col className="mt-2 mt-md-0 text-md-right align-self-center" lg="4 " md='6' xs="12">
                                 <Button className="mr-2" color="primary" size="md" onClick={createTopic} >
                                    <span className="btn-inner--icon mr-1">
                                       <i className="ni ni-fat-add" />
                                    </span>
                                    <span>
                                       {content.add[culture]}
                                    </span>
                                 </Button>
                                 <Button className="btn-neutral mr-2" color="success" size="md">
                                    <span className="btn-inner--icon mr-1 ml-1">
                                       <i className="fab fa-sistrix"></i>
                                    </span>
                                    <span>
                                       {content.search[culture]}
                                    </span>
                                 </Button>
                              </Col>
                           </Row>
                        </CardBody>
                     </Card>
                     <Card>
                        <div className="table-wrapper mt-4">
                           <Tables
                              data={data}
                              deleteTopic={deleteTopic}
                              updateTopic={updateTopic}
                              setId={setId}
                           />
                        </div>
                     </Card>
                  </Container>
               </>
         }
      </>
   );
}

export default Correspondence;
