/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Alternative from "views/pages/dashboards/Alternative.js";
import Buttons from "views/pages/components/Buttons.js";
import Calendar from "views/pages/Calendar.js";
import Cards from "views/pages/components/Cards.js";
import Charts from "views/pages/Charts.js";
import Components from "views/pages/forms/Components.js";
import Dashboard from "views/pages/dashboards/Dashboard.js";
import Elements from "views/pages/forms/Elements.js";
import Google from "views/pages/maps/Google.js";
import Grid from "views/pages/components/Grid.js";
import Icons from "views/pages/components/Icons.js";
//import Lock from "views/pages/examples/Lock.js";
import Lock from "Screen/lock/lock";
//import Login from "views/pages/examples/Login.js";
import Login from "Screen/Login/Login";
import Notifications from "views/pages/components/Notifications.js";
import Pricing from "views/pages/examples/Pricing.js";
import Profile from "views/pages/examples/Profile.js";
import ReactBSTables from "views/pages/tables/ReactBSTables.js";
//import Register from "views/pages/examples/Register.js";
import Register from "Screen/register/register";
import RTLSupport from "views/pages/examples/RTLSupport.js";
import Sortable from "views/pages/tables/Sortable.js";
import Tables from "views/pages/tables/Tables.js";
import Timeline from "views/pages/examples/Timeline.js";
import Typography from "views/pages/components/Typography.js";
import Validation from "views/pages/forms/Validation.js";
import Vector from "views/pages/maps/Vector.js";
import Widgets from "views/pages/Widgets.js";
import Correspondence from "Screen/TypeOfCorrespondence/Correspondence";
import ExternalEmail from "Screen/ExternalEmail/ExternalEmail";
import Outgoing from "Screen/Outgoing/Outgoing";
import WithInInstitution from "Screen/withinInstitution/WithInInstitution";
import OutsideDevice from "Screen/OutsideDevice/OutsideDevice";
import OutgoingEmail from 'Screen/OutgoingEmail/OutgoingEmail';
import MovementsInside from 'Screen/MovementsInside/MovementsInside';
import MovementsOutside from 'Screen/MovementsOutside/MovementsOutside';
import MovementsGraph from 'Screen/components/MovementsGraph';
import CreateCorrespondence from 'Screen/CreateCorrespondence/CreateCorrespondence';
import CreateOutCorrespondence from 'Screen/CreateOutCorrespondence/CreateOutCorrespondence';
import ReceivedInsideDevice from 'Screen/ReceivedInsideDevice/ReceivedInsideDevice';
import ReceivedInsideInstitution from 'Screen/ReceivedInsideInstitution/ReceivedInsideInstitution';
import ReceivedOutsideDevice from 'Screen/ReceivedOutsideDevice/ReceivedOutsideDevice';
import EditCorrespondence from 'Screen/EditCorrespondence/EditCorrespondence';
import Requests from "Screen/Requests/Requests";
import OutgoingRequests from "Screen/OutgoingRequests/OutgoingRequests";
import ExternalReceived from "Screen/ExternalReceived/ExternalReceived";
import CreateExternalReceived from "Screen/CreateExternalReceived/CreateExternalReceived";
import Marginalization from "Screen/Marginalization/Marginalization";
import CreateWithinInstitution from "Screen/CreateWithinInstitution/CreateWithinInstitution";
import BranchSettings from "Screen/BranchSettings/BranchSettings";
import InternalReceived from "Screen/InternalReceived/InternalReceived";
import EditExternalReceived from "Screen/EditExternalReceived/EditExternalReceived";
import MarginalizationSettings from "Screen/MarginalizationSettings/MarginalizationSettings";
import ExtarnalReceivedInside from "Screen/ExternalReceived/ExternalReceivedInside";
//ReceivedInsideDevice
const routes = [
  // { // single
  //   path: "/charts",
  //   name: "Charts",
  //   icon: "ni ni-chart-pie-35 text-info",
  //   component: Charts,
  //   layout: "/admin",
  // },
  // { multi
  //   collapse: true, // in case of single dont emport it or declare false
  //   name: "Dashboards",
  //   icon: "ni ni-shop text-primary",
  //   state: "dashboardsCollapse",
  //   // views: [
  //   {
  //     path: "/dashboard",
  //     name: "Dashboard",
  //     miniName: "D",
  //     component: Dashboard,
  //     layout: "/ar-admin",
  //   },
  //   {
  //     path: "/dashboard",
  //     name: "Dashboard",
  //     miniName: "D",
  //     component: Dashboard,
  //     layout: "/en-admin",
  //   },
  //   {
  //     path: "/alternative-dashboard",
  //     name: "Alternative",
  //     miniName: "A",
  //     component: Alternative,
  //     layout: "/admin",
  //   },
  // ],
  // },
    // global hidden
    {
      collapse: true,
      name: "globalRouts",
      icon: "fas fa-history text-info",
      state: "componentsCollapse",
      views: [
        true && {
          path: "/request/:id",       
          component: OutgoingRequests,
          layout: "/en-admin",
        },
        true && {
          path: "/request/:id",
          component: OutgoingRequests,
          layout: "/ar-admin",
        },
        {
          path: "/createexternalreceived",
          name: "outgoing",
          miniName: "B",
          component: CreateExternalReceived,
          layout: "/en-admin",
        },
        {
          path: "/createexternalreceived",
          name: "outgoing",
          miniName: "B",
          component: CreateExternalReceived,
          layout: "/ar-admin",
        },
        {
          path: "/externalReceived/:id",
          name: "outgoing",
          miniName: "B",
          component: EditExternalReceived,
          layout: "/ar-admin",
        },
        {
          path: "/externalReceived/:id",
          name: "outgoing",
          miniName: "B",
          component: EditExternalReceived,
          layout: "/en-admin",
        },
        {
          path: "/createcorrespondence",
          name: "outgoing",
          miniName: "B",
          component: CreateCorrespondence,
          layout: "/en-admin",
        },
        {
          path: "/createcorrespondence",
          name: "outgoing",
          miniName: "B",
          component: CreateCorrespondence,
          layout: "/ar-admin",
        },
        {
          path: "/createoutcorrespondence",
          name: "outgoing",
          miniName: "B",
          component: CreateOutCorrespondence,
          layout: "/en-admin",
        },
        {
          path: "/createoutcorrespondence",
          name: "outgoing",
          miniName: "B",
          component: CreateOutCorrespondence,
          layout: "/ar-admin",
        },
        {
          path: "/createwithininstitution",
          name: "outgoing",
          miniName: "B",
          component: CreateWithinInstitution,
          layout: "/ar-admin",
        },
        {
          path: "/createwithininstitution",
          name: "outgoing",
          miniName: "B",
          component: CreateWithinInstitution,
          layout: "/en-admin",
        },
        {
          path: "/editcorrespondence/:id",
          name: "outgoing",
          miniName: "B",
          component: EditCorrespondence,
          layout: "/ar-admin",
        },
        {
          path: "/editcorrespondence/:id",
          name: "outgoing",
          miniName: "B",
          component: EditCorrespondence,
          layout: "/en-admin",
        },
        {
          path: "/outgoingmail/:id",
          name: "outgoing",
          miniName: "B",
          component: OutgoingEmail,
          layout: "/en-admin",
        },
        {
          path: "/externalreceivedInside/:id",
          name: "outgoing",
          miniName: "B",
          component: ExtarnalReceivedInside,
          layout: "/en-admin",
        },
        {
          path: "/outgoingmail/:id",
          name: "outgoing",
          miniName: "B",
          component: OutgoingEmail,
          layout: "/ar-admin",
        },
        {
          path: "/externalreceivedInside/:id",
          name: "outgoing",
          miniName: "B",
          component: ExtarnalReceivedInside,
          layout: "/ar-admin",
        },
        {
          path: "/movementgraph/:id",
          name: "outgoing",
          miniName: "B",
          component: MovementsGraph,
          layout: "/en-admin",
        },
        {
          path: "/movementgraph/:id",
          name: "outgoing",
          miniName: "B",
          component: MovementsGraph,
          layout: "/ar-admin",
        },
        {
          path: "/cards",
          name: "Cards",
          miniName: "C",
          component: Cards,
          layout: "/admin",
        },
        {
          path: "/grid",
          name: "Grid",
          miniName: "G",
          component: Grid,
          layout: "/admin",
        },
        {
          path: "/notifications",
          name: "Notifications",
          miniName: "N",
          component: Notifications,
          layout: "/admin",
        },
        {
          path: "/icons",
          name: "Icons",
          miniName: "I",
          component: Icons,
          layout: "/admin",
        },
        {
          path: "/typography",
          name: "Typography",
          miniName: "T",
          component: Typography,
          layout: "/admin",
        },
        {
          collapse: true,
          name: "Multi Level",
          miniName: "M",
          state: "multiCollapse",
          views: [
            {
              path: "#pablo",
              name: "Third level menu",
              component: () => { },
              layout: "/",
            },
            {
              path: "#pablo",
              name: "Just another link",
              component: () => { },
              layout: "/",
            },
            {
              path: "#pablo",
              name: "One last link",
              component: () => { },
              layout: "/",
            },
          ],
        },
      ],
    },
  {
    path: "/dashboard",
    name: "Dashboard",
    arName: "لوحة القيادة",
    icon: "ni ni-shop text-primary",
    component: Dashboard,
    layout: "/en-admin",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    arName: "لوحة القيادة",
    icon: "ni ni-shop text-primary",
    component: Dashboard,
    layout: "/ar-admin",
  },
  {
    collapse: true,
    name: "Activities",
    arName: "الأنشطة",
    icon: "fas fa-history text-info",
    state: "componentsCollapse",
    views: [
      {
        path: "/buttons",
        name: "Buttons",
        miniName: "B",
        component: Buttons,
        layout: "/admin",
      },
      {
        path: "/cards",
        name: "Cards",
        miniName: "C",
        component: Cards,
        layout: "/admin",
      },
      {
        path: "/grid",
        name: "Grid",
        miniName: "G",
        component: Grid,
        layout: "/admin",
      },
      {
        path: "/notifications",
        name: "Notifications",
        miniName: "N",
        component: Notifications,
        layout: "/admin",
      },
      {
        path: "/icons",
        name: "Icons",
        miniName: "I",
        component: Icons,
        layout: "/admin",
      },
      {
        path: "/typography",
        name: "Typography",
        miniName: "T",
        component: Typography,
        layout: "/admin",
      },
      {
        collapse: true,
        name: "Multi Level",
        miniName: "M",
        state: "multiCollapse",
        views: [
          {
            path: "#pablo",
            name: "Third level menu",
            component: () => { },
            layout: "/",
          },
          {
            path: "#pablo",
            name: "Just another link",
            component: () => { },
            layout: "/",
          },
          {
            path: "#pablo",
            name: "One last link",
            component: () => { },
            layout: "/",
          },
        ],
      },
    ],
  },
  // Correspondence 
  {
    collapse: true, // in case of single dont emport it or declare false
    name: "Correspondence matters ",
    arName: "شؤون المراسلات",
    icon: "far fa-newspaper text-pink",
    state: "correspondence",
    layout: "/en-admin",
    views: [
      {
        path: "/correspondence",
        name: "Types of Correspondence ",
        arName: "أنواع المراسلات",
        miniName: "T",
        component: Correspondence,
        layout: "/en-admin",
      },
      {
        path: "/externalemail",
        name: "External Email",
        arName: "البريد الالكتروني الخارجي",
        miniName: "E",
        component: ExternalEmail,
        layout: "/en-admin",
      },
      {
        path: "/branchsettings",
        name: "Branch Settings",
        arName: "إعدادات الفروع",
        miniName: "B",
        component: BranchSettings,
        layout: "/en-admin",
      },
      {
        path: "/marginalizationsettings",
        name: "Marginalization Settings",
        arName: "إعدادات التهميشات",
        miniName: "M",
        component: MarginalizationSettings,
        layout: "/en-admin",
      },
    ],
  },
  {
    collapse: true, // in case of single dont emport it or declare false
    name: "Correspondence matters ",
    arName: "شؤون المراسلات",
    icon: "far fa-newspaper text-pink",
    state: "correspondence",
    layout: "/ar-admin",
    views: [
      {
        path: "/correspondence",
        name: "Types of Correspondence",
        arName: "أنواع المراسلات",
        miniName: "T",
        component: Correspondence,
        layout: "/ar-admin",
      },
      {
        path: "/externalemail",
        name: "External Email",
        arName: "البريد الالكتروني الخارجي",
        miniName: "E",
        component: ExternalEmail,
        layout: "/ar-admin",
      },
      {
        path: "/branchsettings",
        name: "Branch Settings",
        arName: "إعدادات الفروع",
        miniName: "B",
        component: BranchSettings,
        layout: "/ar-admin",
      },
      {
        path: "/marginalizationsettings",
        name: "Marginalization Settings",
        arName: "إعدادات التهميشات",
        miniName: "M",
        component: MarginalizationSettings,
        layout: "/ar-admin",
      },
    ],
  },
  {
    collapse: true, // in case of single dont emport it or declare false
    name: "Received",
    arName: "الوارد",
    icon: "far fa-folder-open text-info",
    state: "recived",
    layout: "/ar-admin",
    views: [
      {
        path: "/internalreceived",
        name: "Internal received",
        arName: "سجل الوارد الداخلي",
        miniName: "د",
        component: InternalReceived,
        layout: "/ar-admin",
      },
      {
        path: "/externalReceived",
        name: "External received log",
        arName: "سجل الوارد الخارجي",
        miniName: "E",
        component: ExternalReceived,
        layout: "/ar-admin",
      },
      {
        path: "/marginalization",
        name: "Marginalizations",
        arName: "التهميشات",
        miniName: "E",
        component: Marginalization,
        layout: "/ar-admin",
      },
    ],
  },
  {
    collapse: true, // in case of single dont emport it or declare false
    name: "Received",
    arName: "الوارد",
    icon: "far fa-folder-open text-info",
    state: "recived",
    layout: "/en-admin",
    views: [
      {
        path: "/internalreceived",
        name: "Internal received",
        arName: "سجل الوارد الداخلي",
        miniName: "I",
        component: InternalReceived,
        layout: "/en-admin",
      },
      {
        path: "/externalReceived",
        name: "External received log",
        arName: "سجل الوارد الخارجي",
        miniName: "E",
        component: ExternalReceived,
        layout: "/en-admin",
      },
      {
        path: "/marginalization",
        name: "Marginalizations",
        arName: "التهميشات",
        miniName: "E",
        component: Marginalization,
        layout: "/en-admin",
      },
    ],
  },

  {
    collapse: true, // in case of single dont emport it or declare false
    name: "Outgoing",
    arName: "الصادر",
    icon: "far fa-paper-plane text-success",
    state: "outgoing",
    layout: "/en-admin",
    views: [
      {
        path: "/outgoing",
        name: "Outgoing within the branch",
        arName: "الصادر داخل الفرع",
        miniName: "T",
        component: Outgoing,
        layout: "/en-admin",
      },
      {
        path: "/withininstitution",
        name: "Outgoing within the institution",
        arName: "الصادر من داخل المؤسسة",
        miniName: "W",
        component: WithInInstitution,
        layout: "/en-admin",
      },
      {
        path: "/outsidedevice",
        name: "External Outgoing",
        arName: "الصادر الخارجي",
        miniName: "O",
        component: OutsideDevice,
        layout: "/en-admin",
      },
      true && {
        path: "/requests",
        name: "Approval of requests",
        miniName: "R",
        component: Requests,
        layout: "/en-admin",
      },
    ],
  },
  {
    collapse: true, // in case of single dont emport it or declare false
    name: "Outgoing",
    arName: "الصادر",
    icon: "far fa-paper-plane text-success",
    state: "outgoing",
    layout: "/ar-admin",
    views: [
      {
        path: "/outgoing",
        name: "Outgoing within the branch",
        arName: "الصادر داخل الفرع",
        miniName: "ص",
        component: Outgoing,
        layout: "/ar-admin",
      },
      {
        path: "/withininstitution",
        name: "Outgoing from the institution",
        arName: "الصادر الى داخل المؤسسة",
        miniName: "د",
        component: WithInInstitution,
        layout: "/ar-admin",
      },
      {
        path: "/outsidedevice",
        name: "External Outgoing",
        arName: "الصادر الخارجي",
        miniName: "خ",
        component: OutsideDevice,
        layout: "/ar-admin",
      },
      true && {
        path: "/requests",
        name: "Approval of requests",
        arName: "اعتماد الطلبات",
        miniName: "ط",
        component: Requests,
        layout: "/ar-admin",
      },
    ],
  },
  {
    collapse: true, // in case of single dont emport it or declare false
    name: "Email Movements",
    arName: "حركات البريد",
    icon: "fas fa-shoe-prints text-danger",
    state: "movements",
    layout: "/ar-admin",
    views: [
      {
        path: "/movementsinside",
        name: "Email Movements",
        arName: "حركات الوارد الداخلي",
        miniName: "د",
        component: MovementsInside,
        layout: "/ar-admin",
      },
      {
        path: "/movementsoutside",
        name: "Movements outside the device",
        arName: "جركات الوارد الخارجي",
        miniName: "خ",
        component: MovementsOutside,
        layout: "/ar-admin",
      },
    ],
  },
  {
    collapse: true, // in case of single dont emport it or declare false
    name: "Movements",
    arName: "الحركات",
    icon: "fab fa-redhat text-danger",
    state: "movements",
    layout: "/en-admin",
    views: [
      {
        path: "/movementsinside",
        name: "Movements inside the device",
        arName: "الحركات داخل الجهاز",
        miniName: "I",
        component: MovementsInside,
        layout: "/en-admin",
      },
      {
        path: "/movementsoutside",
        name: "Movements outside the device",
        arName: "الحركات خارج الجهاز",
        miniName: "O",
        component: MovementsOutside,
        layout: "/en-admin",
      },
    ],
  },

  {
    collapse: true, // in case of single dont emport it or declare false
    name: "Reports",
    arName: "التقارير",
    icon: "far fa-file-alt bg-secondary",
    state: "reports",
    layout: "/en-admin",
    views: [
      {
        path: "/storage",
        name: "Reports issued",
        arName: "التقارير الصادرة",
        miniName: "S",
        component: () => { },
        layout: "/en-admin",
      },
      {
        path: "/splier",
        name: "Reports received",
        arName: "التقارير الواردة",
        miniName: "S",
        component: () => { },
        layout: "/en-admin",
      },
      {
        path: "/splier",
        name: "Free reports",
        arName: "التقارير الحرة",
        miniName: "S",
        component: () => { },
        layout: "/en-admin",
      },
    ],
  },
  {
    collapse: true, // in case of single dont emport it or declare false
    name: "Reports",
    arName: "التقارير",
    icon: "far fa-file-alt bg-secondary",
    state: "reports",
    layout: "/ar-admin",
    views: [
      {
        path: "/storage",
        name: "Reports issued",
        arName: "التقارير الصادرة",
        miniName: "S",
        component: () => { },
        layout: "/ar-admin",
      },
      {
        path: "/splier",
        name: "Reports received",
        arName: "التقارير الواردة",
        miniName: "S",
        component: () => { },
        layout: "/ar-admin",
      },
      {
        path: "/splier",
        name: "Free reports",
        arName: "التقارير الحرة",
        miniName: "S",
        component: () => { },
        layout: "/ar-admin",
      },
    ],
  },

  {
    collapse: true,
    name: "auth",
    icon: "far fa-user text-orange",
    state: "examplesCollapse",
    views: [
      {
        path: "/pricing",
        name: "Pricing",
        miniName: "P",
        component: Pricing,
        layout: "/auth",
      },
      {
        path: "/login",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/ar-auth",

      },
      {
        path: "/login",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/en-auth",

      },
      {
        path: "/register",
        name: "Register",
        miniName: "R",
        component: Register,
        layout: "/en-auth",
      },
      {
        path: "/register",
        name: "Register",
        miniName: "R",
        component: Register,
        layout: "/ar-auth",
      },
      {
        path: "/lock",
        name: "Lock",
        miniName: "L",
        component: Lock,
        layout: "/en-auth",
      },
      {
        path: "/lock",
        name: "Lock",
        miniName: "L",
        component: Lock,
        layout: "/ar-auth",
      },
    ]
  },
  //Correspondence


  // {
  //   collapse: true,
  //   name: "Maps",
  //   icon: "ni ni-map-big text-primary",
  //   state: "mapsCollapse",
  //   views: [
  //     {
  //       path: "/google",
  //       name: "Google",
  //       miniName: "G",
  //       component: Google,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/vector",
  //       name: "Vector",
  //       miniName: "V",
  //       component: Vector,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   path: "/widgets",
  //   name: "Widgets",
  //   icon: "ni ni-archive-2 text-green",
  //   component: Widgets,
  //   layout: "/admin",
  // },
  // {
  //   path: "/charts",
  //   name: "Charts",
  //   icon: "ni ni-chart-pie-35 text-info",
  //   component: Charts,
  //   layout: "/admin",
  // },
  // {
  //   path: "/calendar",
  //   name: "Calendar",
  //   icon: "ni ni-calendar-grid-58 text-red",
  //   component: Calendar,
  //   layout: "/admin",
  // },
];

export default routes;
