// import swal from 'sweetalert';
import env from '../env';
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";

// const ipcRenderer = window.require('electron').ipcRenderer

export default (fn, acttion, setAlert) => {
    fn().catch(function (err) {
        console.log('here');
        console.log(err.response);
        if (!err.response) {
            return setAlert(
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="please check your connection"
                    onConfirm={() => setAlert(null)}
                    onCancel={() => setAlert(null)}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                </ReactBSAlert>
            );
        } else if (err.response.status === 401) {
            localStorage.clear();
            // ipcRenderer.invoke(`unauthorize-event`);

            setAlert(
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title={err.response.data.message}
                    onConfirm={() => setAlert(null)}
                    onCancel={() => setAlert(null)}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                </ReactBSAlert>
            ) 
            // this need to be fixed
            window.location.replace(`${env.Origin()}/ar-auth/login`);
        } else if (err.response.status === 400) {
            setAlert(
              <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title={err.response.data.errors.Messages}
                onConfirm={() => setAlert(null)}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="warning"
                confirmBtnText="Ok"
                btnSize=""
              >
              </ReactBSAlert>
            )
        }

    }).finally(function (params) {
        acttion();
    })
}