import React, { useState } from "react";
// react router
import {
   BrowserRouter as Router,
   Switch,
   Route,
   Link,
   useRouteMatch,
   useParams,
   useHistory
} from "react-router-dom";

// reactstrap components
import {
   Badge,
   DropdownMenu,
   DropdownItem,
   UncontrolledDropdown,
   DropdownToggle,
   Media,
   Progress,
   Table,
   Input,
   UncontrolledTooltip,
   Container
} from "reactstrap";
import ModalPopup from '../components/ModalPopup';

function Tables(props) {

   const [deleteModal, setDeleteModal] = useState(false);
   const [id, setId] = useState('');
   const history = useHistory();

   const handleClick = email => {
      history.push(`/${props.culture}-admin/externalreceivedInside/${email.id}`)
   };

   return (
      <>
         <Table className="align-items-center" responsive>
            <thead className="thead-light">
               <tr>
                  <th scope="col">{props.content.sender[props.culture]}</th>
                  <th scope="col">{props.content.receivedDate[props.culture]}</th>
                  <th scope="col">{props.content.index[props.culture]}</th>
                  <th scope="col">{props.content.serialNumber[props.culture]}</th>
                  <th scope="col">{props.content.senderSide[props.culture]}</th>
                  <th scope="col">{props.content.receivingSide[props.culture]}</th>
               </tr>
            </thead>
            <tbody>
               {props.data.map(email => (
                  <tr className='table-row' key={email.id} onClick={() => handleClick(email)}>
                     <th scope="row">
                        <Media className="align-items-center">
                           {/* <a
                              className="avatar rounded-circle"
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                           >
                              <img
                                 alt="..."
                                 src={"http://localhost:3000/static/media/bootstrap.bd712487.jpg"}
                              />
                           </a> */}
                           <Media>
                              <span className="mb-0 text-sm">
                                 {email.senderName}
                              </span>
                           </Media>
                        </Media>
                     </th>
                     <td>
                        <span className="badge-dot">
                           {email.correspondenceDate?.split('T')[0]}
                        </span>
                     </td>
                     <td>
                        <span>{email.machineNumber}</span>
                     </td>
                     <td>
                        <span>{email.serialNumber}</span>
                     </td>
                     <td>
                        <span>{email.reciverName}</span>
                     </td>
                     <td></td>
                  </tr>
               ))}
               <ModalPopup
                  modal={deleteModal}
                  setModal={setDeleteModal}
                  text="Delete"
                  setId={setId}
                  fn={props.deleteEmail}
                  color='danger'
               >
                  <div className="modal-body">Delete Name</div>
                  <Container>
                     <h2>Are you sure you want to delete this email</h2>
                  </Container>
               </ModalPopup>
            </tbody>
         </Table>
      </>
   );
}

export default Tables