import React, { useState, useEffect, useRef, useCallback } from "react";
// react plugin that creates text editor
// import ReactQuill from "react-quill";
import SimpleHeader from "components/Headers/SimpleHeader";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Button,
  Container,
  Row,
  Col,
  ListGroupItem,
  ListGroup,
} from "reactstrap";
import { useRouteMatch, useLocation, useParams } from "react-router-dom";
// javascript plugin that creates nice dropzones for files
// import Dropzone from "dropzone";
import file from "../../assets/img/icons/file2.png";
import getCulture from "utils/getCulture";
import { Editor } from "@tinymce/tinymce-react";
import catchAsync from "helper/catchAsync";
import axios from "axios";
import env from "env";
import Spinner from "components/Spinner";
import RequestHandler from "helper/RequestHandler";
import content from "./content";
import { useDropzone } from "react-dropzone";
import imgIcon from "assets/img/dropzone/gallery.png";
import fileIcon from "assets/img/dropzone/file.png";
import word from "assets/img/dropzone/word.png";
import powerpoint from "assets/img/dropzone/powerpoint.png";
import pdf from "assets/img/dropzone/PDF.svg";
import ReactBSAlert from "react-bootstrap-sweetalert";
import "assets/css/custom.css";
// this for text editor
// Dropzone.autoDiscover = false;

function EditCorrespondence() {
  let rout = useRouteMatch();
  let culture = getCulture(rout.url);
  const [editor, setEditor] = useState("");
  const [data, setData] = useState([]);
  const [alert, setAlert] = useState();
  const [successAlert, setSuccessAlert] = useState("");
  const [loading, setLoading] = useState(true);
  const [secondLoading, setSecondLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const location = useLocation();
  const { email } = location.state || false;
  const [files, setFiles] = useState([]);
  const routeId = useParams().id;

  const obj = {
    id: useState(""),
    title: useState(""),
    topics: useState(""),
    decisionNo: useState(0),
    decisionDate: useState(0),
    originality: useState(0),
    security: useState(0),
    importance: useState(0),
    branch: useState([
      "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      "3fa85f64-5717-4562-b3fc-2c963f66afa7 ",
    ]),
    department: useState([
      "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      "3fa85f64-5717-4562-b3fc-2c963f66afa7",
    ]),
    division: useState([
      "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      "3fa85f64-5717-4562-b3fc-2c963f66afa7",
    ]),
    unit: useState([
      "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      "3fa85f64-5717-4562-b3fc-2c963f66afa7",
    ]),
    paperCount: useState(1),
    notes: useState(""),
    email: useState(""),
    description: useState(""),
    attachmentTitle: useState(""),
    senderId: useState(""),
    sender: useState(""),
    state: useState(""),
    qr: useState(""),
    logDate: useState(""),
    machineNumber: useState(""),
    serialNumber: useState(""),
    topicId: useState(""),
    createDate: useState(""),
    lastEditDate: useState(""),
    contentLanguage: useState(""),
    creatorId: useState(""),
    content: useState(""),
    decisionNumber: useState(""),
    receiver: useState(""),
    topic: useState(""),
  };

  const setter = (key, val) => {
    obj[key]?.[1](val || "");
  };

  // const token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxMDU0NTgyOTE0YWY0MTUyY2EyYmQxNSIsImlhdCI6MTYzNjI3MTQwOCwiZXhwIjoxNjQ0MDQ3NDA4fQ.J5SuyBj8Kf_LyBhAEQzdBk_oeHxAIXjixqIvEhoHeF8`;
  const token = localStorage.getItem(btoa("token"));

  const editorRef = useRef(null);
  const handleEditorChange = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
      obj.content[1](editorRef.current.getContent());
    }
  };

  const onDrop = (acceptedFiles) => {
    console.log(...acceptedFiles);
    const newFiles = [...files, ...acceptedFiles];
    // newFiles.push(acceptedFiles);
    console.log("fff", newFiles);
    setFiles(newFiles);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const checkImage = (file, name) => {
    const extension = name.substr(name.lastIndexOf(".") - name.length + 1);

    if (file.startsWith("image")) return imgIcon;
    else if (extension === "pdf") return pdf;
    else if (extension === "docx" || extension === "dot") return word;
    else if (
      extension === "pptx" ||
      extension === "ppt" ||
      extension === "ppsx"
    )
      return powerpoint;
    else return fileIcon;
  };

  const handleDelete = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    console.log({ newFiles });
    setFiles(newFiles);
  };

  const handleUpdate = (e) =>
    catchAsync(
      async () => {
        e.preventDefault();
        setSpinner(true);
        const schema = {
          qr: "String",
          title: obj.title[0],
          decisionNumber: Number(obj.decisionNumber[0]),
          decisionDate: obj.decisionDate[0],
          contentLanguage: Number(obj.contentLanguage[0]),
          originality: Number(obj.originality[0]),
          Security: Number(obj.security[0]),
          importance: Number(obj.importance[0]),
          branchId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          departmentId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          divisionId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          unitId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          paperCount: Number(obj.paperCount[0]),
          content: obj.content[0],
          notes: obj.notes[0],
          // machineNumber: obj.machineNumber[0],
          // serialNumber: obj.serialNumber[0],
          // topicId: obj.topicId[0],
          creatorId: "daf4fdbd-f861-4bee-89b4-a4a10c31d743",
          lastEditDate: "2021-09-28",
          createDate: "2021-09-28",
          description: obj.description[0].trim(),
          attachmentTitle: obj.attachmentTitle[0].trim(),
        };

        console.log(schema);
        const result = await axios.put(
          `${env.activeServer()}/Correspondences/update/${routeId}`,
          schema,
          {
            headers: { Authorization: token },
          }
        );

        if (result.status === 200) {
          setSuccessAlert(
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Success"
              onConfirm={() => setSuccessAlert("")}
              onCancel={() => setSuccessAlert("")}
              confirmBtnBsStyle="info"
              confirmBtnText="Ok"
              btnSize=""
            >
              {result.statusText}
            </ReactBSAlert>
          );
        }
      },
      () => {
        setSpinner(false);
      },
      setAlert
    );

  return (
    <div>
      {alert}
      {successAlert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      {loading || secondLoading ? (
        <>
          <RequestHandler
            fn={async () => {
              console.log("here", routeId);

              const result = await axios.get(
                `${env.activeServer()}/Correspondences/preload`,
                {
                  headers: { Authorization: token },
                }
              );
              if (result.status === 200) {
                setData(result.data.data);
              }
            }}
            setAlert={setAlert}
            setLoading={setLoading}
            gate="#29bb64"
            bg="#fff"
          />
          <RequestHandler
            fn={async () => {
              const result = await axios.get(
                `${env.activeServer()}/Correspondences/${routeId}`,
                {
                  headers: { Authorization: token },
                }
              );

              if (result.status === 200) {
                for (const prop in result.data) {
                  setter(prop, result.data[prop]);
                }
              }
            }}
            setAlert={setAlert}
            setLoading={setSecondLoading}
            gate="#29bb64"
            bg="#fff"
          />
        </>
      ) : (
        <>
          <SimpleHeader name="Form elements" parentName="Forms" />
          <Container className="mt--6" fluid>
            <Form onSubmit={handleUpdate}>
              <Card className="mb-4">
                <Card>
                  <CardHeader>
                    <h3 className="mb-0">{content.editCorres[culture]}</h3>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example3cols1Input"
                          >
                            {content.title[culture]}
                          </label>
                          <Input
                            placeholder={content.title[culture]}
                            type="text"
                            value={obj.title[0]}
                            onChange={(e) => setter("title", e.target.value)}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label htmlFor="exampleFormControlSelect1">
                            {content.topics[culture]}
                          </label>
                          <Input
                            required
                            type="select"
                            multiple={false}
                            defaultValue={obj.topic[0] || null}
                            onChange={(e) => setter("topics", e.target.value)}
                          >
                            <option disabled value>
                              {" "}
                              -- {content.selectOption[culture]} --{" "}
                            </option>
                            {data.topics.map((topic) => (
                              <option key={topic.id} value={topic.id}>
                                {topic.name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example3cols1Input"
                          >
                            {content.decisionNo[culture]}
                          </label>
                          <Input
                            required
                            type="number"
                            min="1"
                            value={obj.decisionNumber[0]}
                            onChange={(e) =>
                              setter("decisionNumber", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example3cols1Input"
                          >
                            {content.decisionDate[culture]}
                          </label>
                          <Input
                            required
                            type="date"
                            value={`${obj.decisionDate[0]}`.split("T")[0]}
                            onChange={(e) =>
                              setter("decisionDate", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label htmlFor="exampleFormControlSelect1">
                            {content.contentLang[culture]}
                          </label>
                          <Input
                            required
                            type="select"
                            multiple={false}
                            defaultValue={obj.contentLanguage[0]}
                            onChange={(e) =>
                              setter("contentLanguage", e.target.value)
                            }
                          >
                            <option disabled value>
                              {" "}
                              -- {content.selectOption[culture]} --{" "}
                            </option>
                            {data.languages.map((lang, index) => (
                              <option key={index} value={index}>
                                {content[lang][culture]}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label htmlFor="exampleFormControlSelect1">
                            {content.originality[culture]}
                          </label>
                          <Input
                            required
                            type="select"
                            multiple={false}
                            defaultValue={obj.originality[0]}
                            onChange={(e) =>
                              setter("originality", e.target.value)
                            }
                          >
                            <option disabled value>
                              {" "}
                              -- {content.selectOption[culture]} --{" "}
                            </option>
                            {data.originalitys.map((item, index) => (
                              <option key={index} value={index}>
                                {content[item][culture]}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label htmlFor="exampleFormControlSelect1">
                            {content.security[culture]}
                          </label>
                          <Input
                            required
                            type="select"
                            multiple={false}
                            defaultValue={obj.security[0]}
                            onChange={(e) => setter("security", e.target.value)}
                          >
                            <option disabled value>
                              {" "}
                              -- {content.selectOption[culture]} --{" "}
                            </option>
                            {data.securitys.map((item, index) => (
                              <option key={index} value={index}>
                                {content[item][culture]}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label htmlFor="exampleFormControlSelect1">
                            {content.importance[culture]}
                          </label>
                          <Input
                            required
                            type="select"
                            multiple={false}
                            defaultValue={obj.importance[0]}
                            onChange={(e) =>
                              setter("importance", e.target.value)
                            }
                          >
                            <option disabled value>
                              {" "}
                              -- {content.selectOption[culture]} --{" "}
                            </option>
                            {data.importances.map((item, index) => (
                              <option key={index} value={index}>
                                {content[item][culture]}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label htmlFor="exampleFormControlSelect1">
                            {content.branch[culture]}
                          </label>
                          <Input
                            required
                            type="select"
                            multiple={false}
                            defaultValue={true}
                            onChange={(e) => setter("branch", e.target.value)}
                          >
                            <option disabled value>
                              {" "}
                              -- {content.selectOption[culture]} --{" "}
                            </option>
                            <option value={obj.branch[0][0]}>Test 1</option>
                            <option value={obj.branch[0][1]}>Test 2</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label htmlFor="exampleFormControlSelect1">
                            {content.department[culture]}
                          </label>
                          <Input
                            required
                            type="select"
                            multiple={false}
                            defaultValue={true}
                            onChange={(e) =>
                              setter("department", e.target.value)
                            }
                          >
                            <option disabled value>
                              {" "}
                              -- {content.selectOption[culture]} --{" "}
                            </option>
                            <option value={obj.department[0][0]}>Test 1</option>
                            <option value={obj.department[0][1]}>Test 2</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label htmlFor="exampleFormControlSelect1">
                            {content.division[culture]}
                          </label>
                          <Input
                            required
                            type="select"
                            multiple={false}
                            defaultValue={true}
                            onChange={(e) => setter("division", e.target.value)}
                          >
                            <option disabled value>
                              {" "}
                              -- {content.selectOption[culture]} --{" "}
                            </option>
                            <option>Test 1</option>
                            <option>Test 2</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label htmlFor="exampleFormControlSelect1">
                            {content.unit[culture]}
                          </label>
                          <Input
                            required
                            type="select"
                            multiple={false}
                            defaultValue={true}
                            onChange={(e) => setter("unit", e.target.value)}
                          >
                            <option disabled value>
                              {" "}
                              -- {content.selectOption[culture]} --{" "}
                            </option>
                            <option>Test 1</option>
                            <option>Test 2</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label htmlFor="exampleFormControlSelect1">
                            {content.paperCount[culture]}
                          </label>
                          <Input
                            required
                            type="number"
                            min="1"
                            value={obj.paperCount[0]}
                            onChange={(e) =>
                              setter("paperCount", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      {email ? (
                        <Col md="4">
                          <FormGroup>
                            <label htmlFor="exampleFormControlSelect1">
                              {content.email[culture]}
                            </label>
                            <Input
                              required
                              type="email"
                              value={obj.email[0]}
                              placeholder={content.email[culture]}
                              onChange={(e) => setter("email", e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                      ) : null}
                    </Row>
                    <Row>
                      <label htmlFor="exampleFormControlSelect1">
                        {content.notes[culture]}
                      </label>
                      <Input
                        required
                        type="textarea"
                        onChange={(e) => setter("notes", e.target.value)}
                        value={obj.notes[0]}
                      ></Input>
                    </Row>
                  </CardBody>
                </Card>
              </Card>
              <Card>
                <div className="custom-text-editor">
                  <Editor
                    required
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    value={`<p>${obj.content[0]}</p>`}
                    apiKey="443cb0f3z6tpkkzuciio2p0jg3x217eftmvqv05v47uz6ety"
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help | forecolor | table",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      formats: {
                        alignright: {
                          selector:
                            "p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video",
                          styles: { direction: "rtl", "text-align": "right" },
                          exact: true,
                        },
                      },
                    }}
                    onEditorChange={handleEditorChange}
                  />
                </div>
              </Card>
              <Card>
                <CardHeader>
                  <h3 className="mb-0">{content.attachements[culture]}</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          {content.title[culture]}
                        </label>
                        <Input
                          placeholder={content.title[culture]}
                          type="text"
                          value={obj.attachmentTitle[0]}
                          onChange={(e) =>
                            setter("attachmentTitle", e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          {content.description[culture]}
                        </label>
                        <Input
                          placeholder={content.description[culture]}
                          type="textarea"
                          value={obj.description[0]}
                          onChange={(e) =>
                            setter("description", e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div
                    className="dropzone dropzone-multiple"
                    id="dropzone-multiple"
                  ></div>
                  <div className="dropzone-custom">
                    <div {...getRootProps()}>
                      <input
                        {...getInputProps({
                          multiple: true,
                        })}
                      />
                      {isDragActive ? (
                        <p className="custom-dropzone-content">
                          <span>{content.drop[culture]}</span>
                        </p>
                      ) : (
                        <p className="custom-dropzone-content">
                          <span>{content.drag[culture]}</span>
                        </p>
                      )}
                    </div>
                    {files.length
                      ? files.map((file, index) => (
                          <div className="dropzone-files" key={index}>
                            <div className="dropzone-content">
                              <div className="dropzone-icon">
                                <img src={checkImage(file.type, file.name)} />
                              </div>
                              <div className="dropzone-name">{file.name}</div>
                            </div>
                            <div className="dropzone-button">
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleDelete(index);
                                }}
                              >
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                  <div className="mt-4">
                    <Button
                      className="btn-icon btn-3"
                      color="primary"
                      type="submit"
                    >
                      <span className="btn-inner--text">
                        {content.save[culture]}
                      </span>
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Form>
          </Container>
        </>
      )}
    </div>
  );
}

export default EditCorrespondence;
