export default {
  sender: {
    ar: "المرسل",
    en: "Sender",
  },
  receivedDate: {
    ar: "تاريخ الاستلام",
    en: "Received Date",
  },
  index: {
    ar: "الرقم الاشاري",
    en: "Index",
  },
  serialNumber: {
    ar: "الرقم التسلسلي",
    en: "Serial Number",
  },
  senderSide: {
    ar: "الجهة المرسلة",
    en: "Sender Side",
  },
  receivingSide: {
    ar: "الجهة المستلمة",
    en: "Receiving Side",
  },
  attachmentCount: {
    ar: "عدد المرفقات",
    en: "Attachment Count",
  },
  messageSubject: {
    ar: "موضوع المراسلة",
    en: "Message Subject",
  },
  title: {
    ar: "سجل الوارد الخارجي",
    en: "External recevied",
  },
  parentTitle: {
    ar: "الوارد",
    en: "Received",
  },
  name: {
    ar: "الإسم",
    en: "Name",
  },
  search: {
    ar: "بحث",
    en: "search",
  },
  email: {
    ar: "البريد الإلكتروني",
    en: "email",
  },
  add: {
    ar: "إضافة",
    en: "Add",
  },
  type: {
    ar: "النوع",
    en: "Types",
  },
  from: {
    ar: "من",
    en: "From",
  },
  to: {
    ar: "إلى",
    en: "To",
  },
  index: {
    ar: "الرقم الإشاري",
    en: "Index",
  },
  types: [
    {
      ar: "شخصي",
      en: "Personal",
    },
    {
      ar: "جهة",
      en: "Institution",
    },
  ],
  action: {
    ar: "العمليات",
    en: "Actions",
  },
  edit: {
    ar: "تعديل",
    en: "edit",
  },
  delete: {
    ar: "حذف",
    en: "delete",
  },
  personal: {
    ar: "شخصي",
    en: "Personal",
  },
  company: {
    ar: "جهة",
    en: "Company",
  },
  new: {
    ar: "جديد",
    en: "New",
  },
  attachment: {
    ar: "المرفقات",
    en: "Attachments",
  },
  eye: {
    ar: "تمت رؤيتها",
    en: "Mark as seen",
  },
  machine: {
    ar: "رقم الآلة",
    en: "Machine Number",
  },
  tables: [
    {
      img: "http://localhost:3000/static/media/bootstrap.bd712487.jpg",
      name: "sender name",
      email: "anastaleb@gmail.com",
      type: {
        ar: "شخصي",
        en: "personal",
      },
      typeLogo: "bg-success",
    },
    {
      img: "http://localhost:3000/static/media/bootstrap.bd712487.jpg",
      name: "اسم عربي",
      email: "anasTT@gmail.com",
      type: {
        ar: "جهة",
        en: "Institution",
      },
      typeLogo: "bg-info",
    },
  ],
  contentLanguage: {
    ar: "لغة المحتوى",
    en: "Content Language",
  },
  attachment: {
    ar: "المرفقات",
    en: "Attachments",
  },
  lastEdit: {
    ar: "أخر تعديل",
    en: "Last Edit",
  },
  serial: {
    ar: "الرقم التسلسلي",
    en: "Serial Number",
  },
  machine: {
    ar: "الرقم الألي",
    en: "Machine Number",
  },
  desctiption: {
    ar: "الوصف",
    en: "Description",
  },
  decisionDate: {
    ar: "تاريخ القرار",
    en: "Decision Date",
  },
  notes: {
    ar: "ملاحظات",
    en: "Notes",
  },
  paperCount: {
    ar: "عدد الاوراق",
    en: "Paper Count",
  },
  state: {
    ar: "الحالة",
    en: "State",
  },
  originality: {
    ar: "أصل المراسلة",
    en: "Originality",
  },
  importance: {
    ar: "الأهمية",
    en: "Importance",
  },
  security: {
    ar: "الحماية",
    en: "Security",
  },
  createdDate: {
    ar: "تاريخ الإنشاء",
    en: "Created Date",
  },
  eye: {
    ar: "تمت القراءة",
    en: "Mark as Read",
  },
  forword: {
    ar: "الموافقة وإرسالها",
    en: "Approve and forward",
  },
  approval: {
    ar: "إعتماد",
    en: "Approval",
  },
  decline: {
    ar: "رفض",
    en: "Decline",
  },
  seenPopup: {
    ar: 'هل أنت متأكد أنك تريد وضع علامة "مقروءة" على هذه المراسلة ؟',
    en: "Are you sure you want to mark this correspondence as read ?",
  },
  approvalPopup: {
    ar: "هل أنت متأكد أنك تريد الموافقة على هذه المراسلة؟",
    en: "Are you sure you want to approve this correspondece ?",
  },
  declinePopup: {
    ar: "هل أنت متأكد أنك تريد رفض هذه المراسلات؟",
    en: "Are you sure you want to decline this correspondence ?",
  },
  forwordPopup: {
    ar: "هل أنت متأكد أنك تريد الموافقةوارسال هذه المراسلة ؟",
    en: "Are you sure you want to accept and send this message ?",
  },
  ok: {
    ar: "موافق",
    en: "OK",
  },
};
