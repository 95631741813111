export default
    {
        title: {
            ar: "الصادر الى داخل المؤسسة",
            en: "Outgoing within the institution"
        },
        parentTitle: {
            ar: "الصادر",
            en: "Outgoings"
        },
        lead: {
            ar: "هذه الشاشة موجودة لحماية حسابك، في حالة خروجك مؤقتا من النظام او عدم استعماله لفترة من الزمن او الخروج منه، رجائا قم بإدخال كملة المرور مجددا اذا كنت تريد الدخول الى النظام، او الضغط على خيار لست انا ذا كنت تريد الدخول بحساب أخر",
            en: "This screen is there to protect your account, if you are temporarily logged out of the system or not using it for a period of time or exit from it, please re enter youre password if you want to enter the system, or click on the “Not me” option if you want to log in with another account"
        },

        name: {
            ar: "الإسم",
            en: "Name"
        },
        search: {
            ar: 'بحث',
            en: 'search'
        },
        all: {
            ar: 'الكل',
            en: 'All'
        },
        waitingForApprove: {
            ar: 'تم الاسال و بالانتظار المراجعة',
            en: 'Submitted and awaiting review '
        },
        seen: {
            ar: 'تم رؤيتها و بإنتظار الموافقة',
            en: 'Seen and waiting for approve'
        },
        approved: {
            ar: 'تم الاعتماد',
            en: 'Approved'
        },
        declined: {
            ar: 'مرفوض',
            en: 'Declined'
        },
        canceled: {
            ar: 'ملغي',
            en: 'Canceled'
        },
        new: {
            ar: 'جديد',
            en: 'New'
        },
        table: {
            project: {
                ar: 'المشروع',
                en: 'project'
            },
            status: {
                ar: 'الحالة',
                en: 'status'
            },
            action: {
                ar: 'العمليات',
                en: 'actions'
            }
        },
        edit: {
            ar: 'تعديل',
            en: 'Edit'
        },
        delete: {
            ar: 'حذف',
            en: 'Delete'
        },
        from: {
            ar: 'من',
            en: 'from'
        },
        to: {
            ar: 'إلى',
            en: 'To'
        },
        select: {
            ar: 'إختيار',
            en: 'Select'
        },
        serial: {
            ar: 'الرقم التسلسلي',
            en: 'Serial Number'
        },
        machine: {
            ar: 'رقم الآلة',
            en: 'Machine Number'
        },
        date: {
            ar: 'التاريخ',
            en: 'Date'
        },
        CWFS: {
            ar: 'تم الانشاء و بانتظار الارسال',
            en: 'Created and waiting for submission'
        },
        send: {
            ar: 'إرسال',
            en: 'Send'
        },
        cancel: {
            ar: 'إلغاء',
            en: 'Cancel'
        },
        sendPopup: {
            ar: 'هل أنت متأكد أنك تريد إرسال هذه المراسلة ؟',
            en: 'Are you sure you want to send this correspondence ?'
        },
        deletePopup: {
            ar: 'هل انت متأكد أنك تريد حذف هذه المراسلة ؟',
            en: 'Are you sure you want to delete this correspondence?'
        },
        cancelPopup: {
            ar: 'هل انت متأكد أنك تريد إلغاء هذه المراسلة ؟',
            en: 'Are you sure you want to cancel this correspondence?'
        },
        grid: [
            {
                img: "http://localhost:3000/static/media/bootstrap.bd712487.jpg",
                name: "english name",
                serial: "N48JD8 9DUF JFD1 KSN3",
                machine: "8349FJ 43R 34R"
            },
            {
                img: "http://localhost:3000/static/media/bootstrap.bd712487.jpg",
                name: "بالعربي",
                serial: "N48JD8 9DUF JFD1 KSN3",
                machine: "8349FJ 43R 34R"
            },
            {
                img: "http://localhost:3000/static/media/bootstrap.bd712487.jpg",
                name: "مخلط Eee",
                serial: "N48JD8 9DUF JFD1 KSN3",
                machine: "8349FJ 43R 34R"
            },
        ]
    }