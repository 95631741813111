export default {
    topics: {
        ar: 'الموضوع',
        en: 'Topics'
    },
    pageTitle: {
        ar: 'انشاء مراسلة خارجية',
        en: 'Create an external correspondance'
    },
    parentTitle: {
        ar: 'الصادر الخارجي',
        en: 'External outgoing'
    },
    title: {
        ar: 'العنوان',
        en: 'Title'
    },
    decisionNo: {
        ar: 'رقم القرار',
        en: 'Decision Number'
    },
    decisionDate: {
        ar: 'تاريخ القرار',
        en: 'Decision Date'
    },
    contentLang: {
        ar: 'لغة المحتوى',
        en: 'Content Language'
    },
    originality: {
        ar: 'الأصل',
        en: 'Originality'
    },
    security: {
        ar: 'الحماية',
        en: 'Security'
    },
    importance: {
        ar: 'الأهمية',
        en: 'Importance'
    },
    branch: {
        ar: 'الفرع',
        en: 'Branch'
    },
    department: {
        ar: 'القسم',
        en: 'Department'
    },
    division: {
        ar: 'الشعبة',
        en: 'Division'
    },
    unit: {
        ar: 'الوحدة',
        en: 'Unit'
    },
    paperCount: {
        ar: 'عدد الصفحات',
        en: 'Paper Count'
    },
    notes: {
        ar: 'الملاحظات',
        en: 'Notes'
    },
    create: {
        ar: 'إنشاء',
        en: 'Create'
    },
    email: {
        ar: 'البريد الإلكتروني',
        en: 'Email'
    },
    drop: {
        ar: 'قم بإسقاط الملفات هنا ...',
        en: 'Drop the files here ...'
    },
    drag: {
        ar: 'قم بسحب وإفلات بعض الملفات هنا ، أو انقر لتحديد الملفات',
        en: 'Drag and drop some files here, or click to select files'
    },
    selectOption: {
        ar: 'حدد اختيارا',
        en: 'select an option'
    },
    En: {
        ar: 'الإنجليزية',
        en: 'English'
    },
    Ar: {
        ar: 'العربية',
        en: 'Arabic'
    },
    Internal_Inside: {
        ar: 'داخلي من الداخل',
        en: 'Internal Inside'
    },
    Internal_Outside: {
        ar: 'داخلي من الخارج',
        en: 'Internal Outside'
    },
    External: {
        ar: 'خارجي',
        en: 'External'
    },
    Original: {
        ar: 'أصلي',
        en: 'Original'
    },
    Scanner: {
        ar: 'الماسح الضوئي',
        en: 'Scanner'
    },
    Fax: {
        ar: 'فاكس',
        en: 'Fax'
    },
    Email: {
        ar: 'البريد الإلكتروني',
        en: 'Email'
    },
    Public: {
        ar: 'عامة',
        en: 'Public'
    },
    Specific: {
        ar: 'محدد',
        en: 'Specific'
    },
    Administration: {
        ar: 'الادارة',
        en: 'Administration'
    },
    Confidential: {
        ar: 'مؤتمن',
        en: 'Confidential'
    },
    NotImportant: {
        ar: 'غير مهم',
        en: 'Not Important'
    },
    LowImportant: {
        ar: 'منخفض مهم',
        en: 'Low Important'
    },
    Important: {
        ar: 'مهم',
        en: 'Important'
    },
    HighImportant: {
        ar: 'مهم جدا',
        en: 'HighImportant'
    },
    Urgent: {
        ar: 'طارئ',
        en: 'Urgent'
    },
    attachements: {
        ar: 'المرفقات',
        en: 'Attachements'
    },
    description: {
        ar: 'وصف',
        en: 'Description'
    },
    machineNumber: {
        ar: 'الرقم الآلي',
        en: 'Machine number'
    },
    serialNumber: {
        ar: 'الرقم التسلسلي',
        en: 'Serial number'
    }
}