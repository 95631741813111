export default {
    sender: {
        ar: 'المرسل',
        en: 'Sender'
    },
    receivedDate: {
        ar: 'تاريخ الاستلام',
        en: 'Received Date'
    },
    index: {
        ar: 'الرقم الاشاري',
        en: "Index"
    },
    serialNumber: {
        ar: 'الرقم التسلسلي',
        en: 'Serial Number'
    },   
    senderSide: {
        ar: 'الجهة المرسلة',
        en: 'Sender Side'
    },    
    receivingSide: {
        ar: 'الجهة المستلمة',
        en: 'Receiving Side'
    },
    attachmentCount: {
        ar: 'عدد المرفقات',
        en: 'Attachment Count'
    },
    messageSubject: {
        ar: 'موضوع المراسلة',
        en: 'Message Subject'
    },
    title: {
        ar: 'إعدادات الفروع',
        en: 'Branch Settings'
    },
    parentTitle: {
        ar: "شؤون المراسلات",
        en: "Correspondence matters"
    },
    name: {
        ar: "الإسم",
        en: "Name"
    },
    search: {
        ar: 'بحث',
        en: 'search'
    },
    email: {
        ar: 'البريد الإلكتروني',
        en: 'email'
    },
    add: {
        ar: 'إضافة',
        en: 'Add'
    },
    type: {
        ar: 'النوع',
        en: 'Types'
    },
    from: {
        ar: 'من',
        en: 'From'
    },
    to: {
        ar: 'إلى',
        en: 'To'
    },
    index: {
        ar: 'الرقم الإشاري',
        en: 'Index'
    },
    branches: {
        ar: 'الفروع',
        en: 'Branches'
    },
    selectOption: {
        ar: 'حدد اختيارا',
        en: 'select an option'
    },
    create: {
        ar: 'إنشاء',
        en: 'Create'
    },
    employees: {
        ar: 'الموظفين',
        en: 'Employees'
    },
    branch: {
        ar: 'الفرع',
        en: 'Branch'
    },
    employee: {
        ar: 'الموظف',
        en: 'Employee'
    },
    functionality: {
        ar: 'الصفة الوظيفية',
        en: 'Functionality'
    },
    types: [
        {
            ar: 'شخصي',
            en: 'Personal'
        },
        {
            ar: 'جهة',
            en: 'Institution'
        }
    ],
    action: {
        ar: 'العمليات',
        en: 'Actions'
    },
    edit: {
        ar: 'تعديل',
        en: 'edit'
    },
    delete: {
        ar: 'حذف',
        en: 'delete'
    },
    personal: {
        ar: 'شخصي',
        en: "Personal"
    },
    company: {
        ar: 'جهة',
        en: 'Company'
    },
    new: {
        ar: 'جديد',
        en: 'New'
    },
    delete: {
        ar: 'حذف',
        en: 'Delete'
    },
    deletePopup: {
        ar: 'هل انت متأكد أنك تريد حذف هذه الإعدادات ؟',
        en: 'Are you sure you want to delete this settings?'
    },
    tables: [
        {
            img: 'http://localhost:3000/static/media/bootstrap.bd712487.jpg',
            name: 'sender name',
            email: 'anastaleb@gmail.com',
            type: {
                ar: 'شخصي',
                en: 'personal'
            },
            typeLogo: 'bg-success'
        },
        {
            img: 'http://localhost:3000/static/media/bootstrap.bd712487.jpg',
            name: 'اسم عربي',
            email: 'anasTT@gmail.com',
            type: {
                ar: 'جهة',
                en: 'Institution'
            },
            typeLogo: 'bg-info'
        },
    ]
}