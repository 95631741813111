import React, { useState } from "react";
import SimpleHeader from "components/Headers/SimpleHeader";
import {
  Button,
  Container,
  Card,
  Row,
  Form,
  FormGroup,
  Input,
  Col,
  CardBody,
  Media,
  UncontrolledCarousel,
  UncontrolledTooltip,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
//get user language
import getCulture from "utils/getCulture";
// react router
import { useRouteMatch, Link, useParams } from "react-router-dom";
//content
import content from "./content";
import ModalPopup from "Screen/components/ModalPopup";
import Spinner from "components/Spinner";
import axios from "axios";
import RequestHanlder from "helper/RequestHandler";
import catchAsync from "helper/catchAsync";
import env from "env";
import DocsCard from "Screen/components/DocsCard";

const ExtarnalReceivedInside = (props) => {
  let rout = useRouteMatch();
  let culture = getCulture(rout.url);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [seenModal, setSeenModal] = useState(false);
  const [forwardModal, setForwardModal] = useState(false);
  const [approvalModal, setApprovalModal] = useState(false);
  const [declineModal, setDeclineModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(null);
  const [successAlert, setSuccessAlert] = useState("");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState("");

  // this id comes from page route
  const routeId = useParams().id;

  // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxMDU0NTgyOTE0YWY0MTUyY2EyYmQxNSIsImlhdCI6MTYzNjI3MTQwOCwiZXhwIjoxNjQ0MDQ3NDA4fQ.J5SuyBj8Kf_LyBhAEQzdBk_oeHxAIXjixqIvEhoHeF8";
  const token = localStorage.getItem(btoa("token"));

  const isFile = (file) => {
    const extension = file.substr(file.lastIndexOf(".") - file.length + 1);

    if (extension === "pdf") return true;
    if (extension === "docx" || extension === "dot") return true;
    if (extension === "pptx" || extension === "ppt" || extension === "ppsx")
      return true;
    if (
      extension === "xlsx" ||
      extension === "xlsm" ||
      extension === "xls" ||
      extension === "xlsb"
    )
      return true;
    return false;
  };

  const files = [...(data?.attachment || "")].filter((item) => isFile(item));
  const imges = [...(data?.attachment || "")].filter((img) => !isFile(img))?.map(img => ({src: img}));
  
  const handleEvent = (api, setModal) =>
    catchAsync(
      async () => {
        setSpinner(true);
        setModal(false);
        const result = await axios.put(
          `${env.activeServer()}/CorrespondencesRequest/${api}/${routeId}`,
          {},
          {
            headers: { Authorization: "Bearer " + token },
          }
        );
        let newState = data.state;
        // if (api === "read") newState = 9;
        // else if (api === "redirect") newState = ;
        // else if (api === "approve") newState = ;
        // else if (api === "decline") newState = ;
        if (result.status === 200) {
          setData(d => ({
            ...d,
            state: newState
          }))
          // show alert
          setSuccessAlert(
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Success"
              onConfirm={() => setSuccessAlert("")}
              onCancel={() => setSuccessAlert("")}
              confirmBtnBsStyle="info"
              confirmBtnText="Ok"
              btnSize=""
            >
              {result.statusText}
            </ReactBSAlert>
          );
        }
      },
      () => {
        setId("");
        setSpinner(false);
      },
      setAlert
    );

  return (
    <div>
      {alert}
      {successAlert}
      {spinner ? <Spinner opacity /> : null}
      {loading ? (
        <RequestHanlder
          fn={async () => {
            console.log("here");
            const reqData = await axios.get(
              `${env.activeServer()}/correspondencesoutside/${routeId}`,
              {
                headers: { Authorization: "Bearer " + token },
              }
            );
            if (reqData.status === 200) {
              setData(reqData.data);
            }
          }}
          setLoading={setLoading}
          setAlert={setAlert}
          gate="#29bb64"
          bg="#fff"
        />
      ) : (
        <>
          <SimpleHeader name="Correspondance" />
          <Container className="mt--6" fluid>
            <Card className="mb-4">
              <CardBody>
                <div className="outgoing-header d-flex justify-content-between">
                  <Media className="media-custom align-items-center">
                    <a
                      className="avatar rounded-circle mr-2 ml-2 avatar-custom"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <img
                        alt="..."
                        src="https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_1280.png"
                      />
                    </a>
                    <Media className="flex-column">
                      <span className="mb-0 text-sm fw-bolder header-name">
                        {data.creatorEmployeeName}
                      </span>
                      <span className="mb-0 text-xs">
                        {data.createDate?.split("T")[0]}
                      </span>
                    </Media>
                    <div className="content-hover">
                      <img
                        alt="..."
                        src="https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_1280.png"
                        className="rounded"
                      />
                      <Media className="flex-column ml-2 mr-2 justify-content-center">
                        <span className="mb-0 text-sm name">
                          {data.creatorEmployeeName}
                        </span>
                        <span className="mb-0 text-sm">
                          {data.createDate?.split("T")[0]}
                        </span>
                      </Media>
                    </div>
                  </Media>
                  {/* <div className="header-buttons">
                    <Button
                      color="secondary"
                      type="button"
                      size="sm"
                      className="ml-2 mr-2"
                    >
                      <i className="fas fa-print mr-1 ml-1"></i>
                      Print
                    </Button>
                    <Button color="danger" type="button" size="sm" outline>
                      <i className="far fa-file-pdf mr-1 ml-1"></i>
                      Show as pdf
                    </Button>
                  </div> */}
                </div>
                <div className="outgoing-mail mt-5 mb-5">
                  <Row className="justify-content-between">
                    <h1>{data.title}</h1>
                  </Row>
                  <p dangerouslySetInnerHTML={{ __html: data.content }}></p>
                </div>
              </CardBody>
            </Card>
            <DocsCard title={content.attachment[culture]} files={files} />
            <Card>
              <CardBody>
                <div className="outgoing-carousel mt-3 mb-6">
                  <UncontrolledCarousel
                    items={imges}
                    className="carosel-custom"
                  />
                </div>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-text-input"
                      >
                        {content.machine[culture]}
                      </label>
                      <Input
                        id="example-text-input"
                        type="text"
                        disabled
                        value={data.machineNumber}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-text-input"
                      >
                        {content.serial[culture]}
                      </label>
                      <Input
                        id="example-text-input"
                        type="text"
                        disabled
                        value={data.serialNumber}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-text-input"
                      >
                        {content.createdDate[culture]}
                      </label>
                      <Input
                        id="example-text-input"
                        type="text"
                        disabled
                        value={data.createDate?.split("T")[0]}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-text-input"
                      >
                        {content.lastEdit[culture]}
                      </label>
                      <Input
                        id="example-text-input"
                        type="text"
                        disabled
                        value={data.lastEditDate?.split("T")[0]}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-text-input"
                      >
                        {content.contentLanguage[culture]}
                      </label>
                      <Input
                        id="example-text-input"
                        type="text"
                        disabled
                        value={data.contentLanguage}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-text-input"
                      >
                        {content.paperCount[culture]}
                      </label>
                      <Input
                        id="example-text-input"
                        type="text"
                        disabled
                        value={data.paperCount}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-text-input"
                      >
                        {content.notes[culture]}
                      </label>
                      <Input
                        id="example-text-input"
                        type="text"
                        disabled
                        value={data.notes}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-text-input"
                      >
                        {content.decisionDate[culture]}
                      </label>
                      <Input
                        id="example-text-input"
                        type="text"
                        disabled
                        value={data.decisionDate?.split("T")[0]}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-text-input"
                      >
                        {content.desctiption[culture]}
                      </label>
                      <Input
                        id="example-text-input"
                        type="text"
                        disabled
                        value={data.desctiption}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-text-input"
                      >
                        {content.security[culture]}
                      </label>
                      <Input
                        id="example-text-input"
                        type="text"
                        disabled
                        value={data.security}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-text-input"
                      >
                        {content.importance[culture]}
                      </label>
                      <Input
                        id="example-text-input"
                        type="text"
                        disabled
                        value={data.importance}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-text-input"
                      >
                        {content.originality?.[culture]}
                      </label>
                      <Input
                        id="example-text-input"
                        type="text"
                        disabled
                        value={data.originality?.[culture]}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-text-input"
                      >
                        {content.state[culture]}
                      </label>
                      <Input
                        id="example-text-input"
                        type="text"
                        disabled
                        value={data.state}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <ModalPopup
                modal={forwardModal}
                setModal={setForwardModal}
                text={content.ok[culture]}
                setId={setId}
                fn={() => handleEvent("redirect", setForwardModal)}
                color="warning"
                name={name}
              >
                <Container>
                  <h4 className="modal-body">
                    {content.forwordPopup[culture]}
                  </h4>
                </Container>
              </ModalPopup>
              <ModalPopup
                modal={seenModal}
                setModal={setSeenModal}
                text={content.ok[culture]}
                setId={setId}
                fn={() => handleEvent("read", setSeenModal)}
                color="primary"
                name={name}
              >
                <Container>
                  <h4 className="modal-body">{content.seenPopup[culture]}</h4>
                </Container>
              </ModalPopup>
              <ModalPopup
                modal={approvalModal}
                setModal={setApprovalModal}
                text={content.approval[culture]}
                setId={setId}
                fn={() => handleEvent("approve", setApprovalModal)}
                color="success"
                name={name}
              >
                <Container>
                  <h4 className="modal-body">
                    {content.approvalPopup[culture]}
                  </h4>
                </Container>
              </ModalPopup>
              <ModalPopup
                modal={declineModal}
                setModal={setDeclineModal}
                text={content.ok[culture]}
                setId={setId}
                fn={() => handleEvent("decline", setDeclineModal)}
                color="danger"
                name={name}
              >
                <Container>
                  <h4 className="modal-body">
                    {content.declinePopup[culture]}
                  </h4>
                </Container>
              </ModalPopup>
            </Card>
          </Container>
        </>
      )}
    </div>
  );
};
export default ExtarnalReceivedInside;
