import React, { useEffect, useState } from "react";
// react router
import {
   BrowserRouter as Router,
   Switch,
   Route,
   Link,
   useRouteMatch,
   useParams
} from "react-router-dom";

// reactstrap components
import {
   Badge,
   DropdownMenu,
   DropdownItem,
   UncontrolledDropdown,
   DropdownToggle,
   Media,
   Progress,
   Table,
   UncontrolledTooltip,
   Container
} from "reactstrap";
import Spinner from "components/Spinner";
import ModalPopup from "Screen/components/ModalPopup";
import catchAsync from "helper/catchAsync";
import axios from 'axios';
import RequestHanlder from 'helper/RequestHandler';
import env from 'env';
import ReactBSAlert from "react-bootstrap-sweetalert";

function Tables(props) {

  const [sendModal, setSendModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState();
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');

//   const token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxMDU0NTgyOTE0YWY0MTUyY2EyYmQxNSIsImlhdCI6MTYzNjI3MTQwOCwiZXhwIjoxNjQ0MDQ3NDA4fQ.J5SuyBj8Kf_LyBhAEQzdBk_oeHxAIXjixqIvEhoHeF8`;
   const token = localStorage.getItem(btoa('token'));

   const handleDelete = () => catchAsync(async () => {
         props.setSpinner(true);
         setDeleteModal(false);
         const result = await axios.delete(`${env.activeServer()}/Correspondencesemail/${id}`, {
            headers: { "Authorization": "Bearer " + token }
         })
         console.log({result})
         if (result.status === 200) {
            // splice the row after send it
            const newData = [...props.data].filter(item => item.id != id);
            props.setData(newData);

            // show alert
            props.setSuccessAlert(
               <ReactBSAlert
                  success
                  style={{ display: "block", marginTop: "-100px" }}
                  title="Success"
                  onConfirm={() => props.setSuccessAlert('')}
                  onCancel={() => props.setSuccessAlert('')}
                  confirmBtnBsStyle="info"
                  confirmBtnText="Ok"
                  btnSize=""
               >
                  {result.statusText}
               </ReactBSAlert>
            );
         }
      }, () => {
         setId('')
         props.setSpinner(false);
      }, props.setAlert)

	 const handleEvent = (api, setModal )=> catchAsync(async () => {
		 props.setSpinner(true);
		 setModal(false)
		 const result = await axios.put(`${env.activeServer()}/Correspondencesemail/${api}/${id}`, {}, {
         headers: { "Authorization": "Bearer " + token }
		 })
		 console.log("resulttt", result)
		 if (result.status === 200) {
				 // splice the row after send it
				 const newData = [...props.data].filter(item => item.id != id);
				 props.setData(newData);

				 // show alert
				 props.setSuccessAlert(
					 <ReactBSAlert
							 success
							 style={{ display: "block", marginTop: "-100px" }}
							 title="Success"
							 onConfirm={() => props.setSuccessAlert('')}
							 onCancel={() => props.setSuccessAlert('')}
							 confirmBtnBsStyle="info"
							 confirmBtnText="Ok"
							 btnSize=""
					 >
							 {result.statusText}
					 </ReactBSAlert>
				 );
		 }
	 }, () => {
		 setId('')
		 props.setSpinner(false);
	 }, props.setAlert)
      
   const { content, culture } = props;
   console.log({...props})
   return (
      <>
         {
            loading ? <RequestHanlder fn={async () => {
              // apiUrl prop comes from TopNav
               const result = await axios.get(`${env.activeServer()}/${props.apiUrl}`, {
                  headers: { "Authorization": "Bearer " + token }
               })
                if(result.status) {
                  console.log("bbbbbbb", result.data.data)
                  props.setData(result.data.data.reverse());
                }
              }}
                setLoading={setLoading}
                setAlert={props.setAlert}
                gate="#29bb64"
                bg="#fff"
              /> :
         <>
            <Table className="align-items-center" responsive>
               <thead className="thead-light">
                  <tr>
                     <th scope="col">{content.name[culture]}</th>
                     <th scope="col">{content.serial[culture]}</th>
                     <th scope="col">{content.machine[culture]}</th>
                     <th scope="col">{content.date[culture]}</th>
                     {props.actions ? <th scope="col">{content.table.action[culture]}</th> : null}
                  </tr>
               </thead>
               <tbody>
                  {
                     props.data.map((item, index) => (
                        <tr key={item.id}>
                           <th scope="row">
                              <Media className="align-items-center">
                                 <Media>
                                    <span className="mb-0 text-sm">
                                       <Link 
                                          to={
                                             props.created ? `/${culture}-admin/outgoingmail/${item.id}`
                                             :`/${culture}-admin/movementgraph/${item.id}` 
                                          }>
                                          {item.title}
                                       </Link>
                                    </span>
                                 </Media>
                              </Media>
                           </th>
                           <td>
                              <span>{item.serialNumber}</span>
                           </td>
                           <td>
                              <span>{item.machineNumber}</span>
                           </td>
                           <td>
                              <span>{new Date().getFullYear() + '-10' + '-1'}</span>
                           </td>
                           {props.actions ?
                           <td>
                              <div className="d-flex align-items-center">
                                 <span className="">
                                    <Link 
                                       to='/'
                                       id={`send${index}`} 
                                       className="table-action"
                                       onClick={e => {
                                          e.preventDefault();
                                          setSendModal(true);
                                          setId(item.id)
                                          setName(item.title)
                                       }} 
                                    >
                                       <i className="far fa-paper-plane fa-lg success-hover"></i>
                                    </Link>
                                    <UncontrolledTooltip
                                       delay={0}
                                       target={`send${index}`}
                                    >
                                       {content.send[culture]}
                                    </UncontrolledTooltip>
                                 </span>
                                 {props.cancel ?
                                    <>
                                       <span className="mr-4">
                                          <Link
                                             to='/'
                                             id={`delete${index}`} 
                                             className="table-action-delete table-action"
                                             onClick={e => {
                                                e.preventDefault();
                                                setDeleteModal(true);
                                                setId(item.id);
                                                setName(item.title)
                                             }}   
                                          >
                                             <i className="fas fa-trash-alt fa-lg"></i>
                                          </Link>
                                          <UncontrolledTooltip
                                             delay={0}
                                             target={`delete${index}`}
                                          >
                                             {content.delete[culture]}
                                          </UncontrolledTooltip>
                                       </span>
                                    </>
                                    :null
                                 }  
                              </div>
                           </td>: null}
                        </tr>
                     ))
                  }
                  <ModalPopup
                     modal={sendModal}
                     setModal={setSendModal}
                     text={content.send[culture]}
                     setId={setId}
                     fn={() => handleEvent("send", setSendModal)}
                     color='success'
                     name={name}
                  >
                     <Container>
                        <h4 className="modal-body">{content.sendPopup[culture]}</h4>
                     </Container>
                  </ModalPopup>
                  {
                     props.cancel ?
                     <>
                        <ModalPopup
                           modal={deleteModal}
                           setModal={setDeleteModal}
                           text={content.delete[culture]}
                           setId={setId}
                           fn={handleDelete}
                           color='danger'
                           name={name}
                        >
                           <Container>
                              <h4 className="modal-body">{content.deletePopup[culture]}</h4>
                           </Container>
                        </ModalPopup>
                     </>
                     :null
                  }
               </tbody>
            </Table>
         </>}
      </>
   );
}

export default Tables