import React, { useState, useEffect, useRef, useCallback } from "react";
// react plugin that creates text editor
// import ReactQuill from "react-quill";
import SimpleHeader from 'components/Headers/SimpleHeader';
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Button,
    Container,
    Row,
    Col,
    ListGroupItem,
    ListGroup,
} from "reactstrap";
import { useRouteMatch, useParams } from 'react-router-dom';
// javascript plugin that creates nice dropzones for files
// import Dropzone from "dropzone";
import file from '../../assets/img/icons/file2.png';
import getCulture from "utils/getCulture";
import catchAsync from "helper/catchAsync";
import axios from 'axios';
import env from 'env';
import Spinner from 'components/Spinner';
import RequestHandler from 'helper/RequestHandler';
import content from './content';
import { useDropzone } from 'react-dropzone'
import imgIcon from 'assets/img/dropzone/gallery.png';
import fileIcon from 'assets/img/dropzone/file.png';
import word from 'assets/img/dropzone/word.png';
import powerpoint from 'assets/img/dropzone/powerpoint.png';
import pdf from 'assets/img/dropzone/PDF.svg';
import ReactBSAlert from "react-bootstrap-sweetalert";

// this for text editor
// Dropzone.autoDiscover = false;


function EditExternalReceived() {

    let rout = useRouteMatch()
    let culture = getCulture(rout.url);
    const [data, setData] = useState([]);
    const [alert, setAlert] = useState();
    const [successAlert, setSuccessAlert] = useState('');
    const [loading, setLoading] = useState(true);
    const [spinner, setSpinner] = useState(false);
    const {id} = useParams();

    const obj = {
      correspondenceDate: useState(''),
      serialNumber: useState(0),
      machineNumber: useState(0),
      senderName: useState(''),
      reciverName: useState(''),
      descraption: useState(''),
      paperCount: useState(''),
      supplierName: useState(''),
    }

    const setter = (key ,val) => {
        obj[key][1](val || '');
    }

    // const token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxMDU0NTgyOTE0YWY0MTUyY2EyYmQxNSIsImlhdCI6MTYzNjI3MTQwOCwiZXhwIjoxNjQ0MDQ3NDA4fQ.J5SuyBj8Kf_LyBhAEQzdBk_oeHxAIXjixqIvEhoHeF8`;
    const token = localStorage.getItem(btoa('token'));
    
    const handleUpdate = e => catchAsync(async () => {
      e.preventDefault();
      setSpinner(true);

      const schema = {
        correspondenceDate: obj.correspondenceDate[0],
        serialNumber: obj.serialNumber[0],
        machineNumber: obj.machineNumber[0],
        senderName: obj.senderName[0],
        reciverName: obj.reciverName[0],
        descraption: obj.descraption[0],
        paperCount: Number(obj.paperCount[0]),
        supplierName: obj.supplierName[0],
      };

      for (const prop in schema) {
        if (schema[prop] === data[prop]) {
          delete schema[prop];
        } 
      }

      const result = await axios.put(`${env.activeServer()}/IncomingLog/${id}`, schema, {
        headers: {'Authorization': token}
      })

      if (result.status === 200) {
        setSuccessAlert(
          <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Success"
          onConfirm={() => setSuccessAlert('')}
          onCancel={() => setSuccessAlert('')}
          confirmBtnBsStyle="info"
          confirmBtnText="Ok"
          btnSize=""
       >
          {result.statusText}
       </ReactBSAlert>
        )
      }
    }, () => {
      setSpinner(false);
    }, setAlert)

    return (
      <div>
          {alert}
          {successAlert}
          {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
          {loading ? 
              <RequestHandler 
                  fn={async () => { 
                      console.log('here')

                      const result = await axios.get(`${env.activeServer()}/correspondencesoutside/${id}`, {
                          headers: {'Authorization': token}
                      })
                      if (result.status === 200) {
                          console.log({result: result.data})
                          console.log({'ffff': result})
                          setData(result.data);
                          for (const prop in obj) {
                            setter(prop, result.data[prop])
                          }
                      }
                  }} 
                  setAlert={setAlert}      
                  setLoading={setLoading}
                  gate="#29bb64"
                  bg="#fff"
              />:
          <>
          <SimpleHeader name="Form elements" parentName="Forms" />
          <Container className="mt--6" fluid>
            <Form onSubmit={handleUpdate}>
              <Card className="mb-4">
                  <Card>
                      <CardHeader>
                          <h3 className="mb-0">{content.page[culture]}</h3>
                      </CardHeader>
                      <CardBody>
                          <Row>
                              <Col md="4">
                                  <FormGroup>
                                      <label
                                          className="form-control-label"
                                          htmlFor="example3cols1Input"
                                      >
                                          {content.receivedDate[culture]}
                                      </label>
                                      <Input
                                          type="date"
                                          value={obj.correspondenceDate[0].split('T')[0]}
                                          onChange={e => setter('correspondenceDate', e.target.value)}
                                          required
                                      />
                                  </FormGroup>
                              </Col>
                              <Col md="4">
                                  <FormGroup>
                                      <label
                                          className="form-control-label"
                                          htmlFor="example3cols1Input"
                                      >
                                          {content.index[culture]}
                                      </label>
                                      <Input
                                          type="Number"
                                          min={0}
                                          value={obj.machineNumber[0]}
                                          onChange={e => setter('machineNumber', e.target.value)}
                                          required
                                      />
                                  </FormGroup>
                              </Col>
                              <Col md="4">
                                  <FormGroup>
                                      <label
                                          className="form-control-label"
                                          htmlFor="example3cols1Input"
                                      >
                                          {content.serialNumber[culture]}
                                      </label>
                                      <Input
                                          min={0}
                                          type="Number"
                                          value={obj.serialNumber[0]}
                                          onChange={e => setter('serialNumber', e.target.value)}
                                          required
                                      />
                                  </FormGroup>
                              </Col>
                              <Col md="4">
                                  <FormGroup>
                                      <label
                                          className="form-control-label"
                                          htmlFor="example3cols1Input"
                                      >
                                          {content.sender[culture]}
                                      </label>
                                      <Input
                                        placeholder={content.sender[culture]}
                                        type="text"
                                        value={obj.senderName[0]}
                                        onChange={e => setter('senderName', e.target.value)}
                                        required
                                      />
                                  </FormGroup>
                              </Col>
                              <Col md="4">
                                  <FormGroup>
                                      <label
                                          className="form-control-label"
                                          htmlFor="example3cols1Input"
                                      >
                                          {content.receiver[culture]}
                                      </label>
                                      <Input
                                        placeholder={content.receiver[culture]}
                                        type="text"
                                        value={obj.reciverName[0]}
                                        onChange={e => setter('reciverName', e.target.value)}
                                        required
                                      />
                                  </FormGroup>
                              </Col>
                              <Col md="4">
                                  <FormGroup>
                                      <label
                                          className="form-control-label"
                                          htmlFor="example3cols1Input"
                                      >
                                          {content.supplierName[culture]}
                                      </label>
                                      <Input
                                        placeholder={content.supplierName[culture]}
                                        type="text"
                                        value={obj.supplierName[0]}
                                        onChange={e => setter('supplierName', e.target.value)}
                                        required
                                      />
                                  </FormGroup>
                              </Col>
                              <Col md="4">
                                  <FormGroup>
                                      <label
                                          className="form-control-label"
                                          htmlFor="example3cols1Input"
                                      >
                                          {content.attachmentCount[culture]}
                                      </label>
                                      <Input
                                        type="number"
                                        value={obj.paperCount[0]}
                                        onChange={e => setter('paperCount', e.target.value)}
                                        required
                                      />
                                  </FormGroup>
                              </Col>
                          </Row>
                          <Row>
                              <label htmlFor="exampleFormControlSelect1">
                                {content.messageSubject[culture]}
                              </label>
                              <Input 
                                required
                                type="textarea" 
                                onChange={e => setter('descraption', e.target.value)}
                                value={obj.descraption[0]}
                                placeholder={content.messageSubject[culture]}
                              ></Input>
                          </Row>
                          <div className='mt-4'>
                              <Button className="btn-icon btn-3" color="primary" type="submit">
                                  <span className="btn-inner--text">{content.create[culture]}</span>
                              </Button>
                          </div>
                      </CardBody>
                  </Card>
              </Card>
            </Form>
          </Container>
          </>
          } 
      </div>
    );
}

export default EditExternalReceived;