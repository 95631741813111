export default {
    sender: {
        ar: 'المرسل',
        en: 'Sender'
    },
    receivedDate: {
        ar: 'تاريخ الاستلام',
        en: 'Received Date'
    },
    index: {
        ar: 'الرقم الاشاري',
        en: "Index"
    },
    serialNumber: {
        ar: 'الرقم التسلسلي',
        en: 'Serial Number'
    },   
    senderSide: {
        ar: 'الجهة المرسلة',
        en: 'Sender Side'
    },    
    receivingSide: {
        ar: 'الجهة المستلمة',
        en: 'Receiving Side'
    },
    attachmentCount: {
        ar: 'عدد المرفقات',
        en: 'Attachment Count'
    },
    messageSubject: {
        ar: 'موضوع المراسلة',
        en: 'Message Subject'
    },
    title: {
        ar: 'سجل الوارد الداخلي',
        en: 'Internal recevied'
    },
    parentTitle: {
        ar: "الوارد",
        en: "Received"
    },
    name: {
        ar: "الإسم",
        en: "Name"
    },
    search: {
        ar: 'بحث',
        en: 'search'
    },
    email: {
        ar: 'البريد الإلكتروني',
        en: 'email'
    },
    statementTitle: {
        ar: 'عنوان البيان',
        en: 'Statement title'
    },
    creator: {
        ar: 'المنشئ',
        en: 'Creator'
    },
    sender: {
        ar: 'المرسل',
        en: 'Sender'
    },
    machineNumber: {
        ar: 'الرقم الآلي',
        en: 'Machine Number'
    },
    date: {
        ar: 'التاريخ',
        en: 'Date'
    },
    add: {
        ar: 'إضافة',
        en: 'Add'
    },
    type: {
        ar: 'النوع',
        en: 'Types'
    },
    from: {
        ar: 'من',
        en: 'From'
    },
    to: {
        ar: 'إلى',
        en: 'To'
    },
    index: {
        ar: 'الرقم الإشاري',
        en: 'Index'
    },
    types: [
        {
            ar: 'شخصي',
            en: 'Personal'
        },
        {
            ar: 'جهة',
            en: 'Institution'
        }
    ],
    action: {
        ar: 'العمليات',
        en: 'Actions'
    },
    edit: {
        ar: 'تعديل',
        en: 'edit'
    },
    delete: {
        ar: 'حذف',
        en: 'delete'
    },
    personal: {
        ar: 'شخصي',
        en: "Personal"
    },
    company: {
        ar: 'جهة',
        en: 'Company'
    },
    new: {
        ar: 'جديد',
        en: 'New'
    },
    destination: {
        ar: "الوجهة",
        en: "Destination"
    },
    tables: [
        {
            img: 'http://localhost:3000/static/media/bootstrap.bd712487.jpg',
            name: 'sender name',
            email: 'anastaleb@gmail.com',
            type: {
                ar: 'شخصي',
                en: 'personal'
            },
            typeLogo: 'bg-success'
        },
        {
            img: 'http://localhost:3000/static/media/bootstrap.bd712487.jpg',
            name: 'اسم عربي',
            email: 'anasTT@gmail.com',
            type: {
                ar: 'جهة',
                en: 'Institution'
            },
            typeLogo: 'bg-info'
        },
    ]
}