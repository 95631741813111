import React, { useState } from 'react';

// reactstrap components
import {
   Card,
   CardHeader,
   CardBody,
   CardTitle,
   Label,
   FormGroup,
   Form,
   Input,
   Button,
   Container,
   Row,
   Col,
} from "reactstrap";

import ReactBSAlert from "react-bootstrap-sweetalert";
import getCulture from "utils/getCulture";
import { useRouteMatch, Link } from 'react-router-dom';
import SimpleHeader from 'components/Headers/SimpleHeader';
import Tables from './Tables';
import content from './content';
import axios from 'axios';
import RequestHanlder from 'helper/RequestHandler';
import catchAsync from 'helper/catchAsync';
import env from 'env';
import Spinner from 'components/Spinner';

const MovementsInside = () => {

   let rout = useRouteMatch();
   let culture = getCulture(rout.url);
   const [toggle, setToggle] = useState(true);
   const [alert, setAlert] = useState('');
   const [loading, setLoading] = useState(true);
   const [data, setData] = useState([]);
   const [name, setName] = useState('');
   const [address, setAddress] = useState('');
   const [id, setId] = useState('');
   const [spinner, setSpinner ] = useState(false);
   const [successAlert, setSuccessAlert] = useState('');
   const [fromDate, setFromDate] = useState('');

   // let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxMDU0NTgyOTE0YWY0MTUyY2EyYmQxNSIsImlhdCI6MTYzNjI3MTQwOCwiZXhwIjoxNjQ0MDQ3NDA4fQ.J5SuyBj8Kf_LyBhAEQzdBk_oeHxAIXjixqIvEhoHeF8";
   const token = localStorage.getItem(btoa('token'));
   
   return (
      <div>
      {successAlert}
      {alert}
      {spinner ? <Spinner opacity /> : null}
      {
         loading ? <RequestHanlder fn={async () => {
            console.log('here')
            const result = await axios.get(`${env.activeServer()}/MovementIncoming`, {
               headers: { "Authorization": "Bearer " + token }
            })
            if (result.status === 200) {
               setData(result.data.data);
            }
         }}
            setLoading={setLoading}
            setAlert={setAlert}
            gate="#29bb64"
            bg="#fff"
         /> 
      : <div>
         <SimpleHeader name={content.title[culture]} parentName={content.parentTitle[culture]} />
         <Container className="mt--6" fluid>
            <Card className="mb-4">
               <form>
                  <CardHeader>
                     <h3 className="mb-0">{content.title[culture]}</h3>
                  </CardHeader>
                  <CardBody>
                     <Row>
                        <Col md="4">
                           <FormGroup>
                              <label
                                 className="form-control-label"
                                 htmlFor="example3cols1Input"
                              >
                                 {content.creator[culture]}
                              </label>
                              <Input
                                 id="example3cols1Input"
                                 type="text"
                                 placeholder={content.creator[culture]}
                                 value={fromDate}
                                 onChange={(e) => setFromDate(e.target.value)}
                              />
                           </FormGroup>
                        </Col>
                        <Col md="4">
                           <FormGroup>
                              <label
                                 className="form-control-label"
                                 htmlFor="example3cols1Input"
                              >
                                 {content.sender[culture]}
                              </label>
                              <Input
                                 id="example3cols1Input"
                                 type="text"
                                 placeholder={content.sender[culture]}
                                 value={fromDate}
                                 onChange={(e) => setFromDate(e.target.value)}
                              />
                           </FormGroup>
                        </Col>
                        <Col md="4">
                           <FormGroup>
                              <label
                                 className="form-control-label"
                                 htmlFor="example3cols1Input"
                              >
                                 {content.statementTitle[culture]}
                              </label>
                              <Input
                                 id="example3cols1Input"
                                 type="text"
                                 placeholder={content.statementTitle[culture]}
                                 value={fromDate}
                                 onChange={(e) => setFromDate(e.target.value)}
                              />
                           </FormGroup>
                        </Col>
                        <Col md="4">
                           <FormGroup>
                              <label
                                 className="form-control-label"
                                 htmlFor="example3cols1Input"
                              >
                                 {content.machineNumber[culture]}
                              </label>
                              <Input
                                 id="example3cols1Input"
                                 type="text"
                                 placeholder={content.machineNumber[culture]}
                                 value={fromDate}
                                 onChange={(e) => setFromDate(e.target.value)}
                              />
                           </FormGroup>
                        </Col>
                        <Col md="4">
                           <FormGroup>
                              <label
                                 className="form-control-label"
                                 htmlFor="example3cols1Input"
                              >
                                 {content.serialNumber[culture]}
                              </label>
                              <Input
                                 id="example3cols1Input"
                                 type="text"
                                 placeholder={content.serialNumber[culture]}
                                 value={fromDate}
                                 onChange={(e) => setFromDate(e.target.value)}
                              />
                           </FormGroup>
                        </Col>
                        <Col md="4">
                           <FormGroup>
                              <label
                                 className="form-control-label"
                                 htmlFor="example3cols1Input"
                              >
                                 {content.date[culture]}
                              </label>
                              <Input
                                 id="example3cols1Input"
                                 type="date"
                                 value={fromDate}
                                 onChange={(e) => setFromDate(e.target.value)}
                              />
                           </FormGroup>
                        </Col>
                        <Col className="mt-2 mt-md-0  align-self-center" xs="12">
                           <Button className="btn-neutral mr-2" color="success" size="md">
                              <span className="btn-inner--icon mr-1 ml-1">
                                 <i className="fab fa-sistrix"></i>
                              </span>
                              <span>
                                 {content.search[culture]}
                              </span>
                           </Button>
                        </Col>
                     </Row>
                  </CardBody>
               </form>
            </Card>
            <Card>
               <Tables 
                  culture={culture} 
                  content={content} 
                  data={data} 
                  setData={setData} 
                  setId={setId}
                  setName={setName}
                  setAddress={setAddress}
                  toggle={toggle}
                  setToggle={setToggle}
               />
            </Card>
         </Container>
      </div>}  
      </div>
   )
}


export default MovementsInside