import React, { useState } from "react";
// react router
import { useHistory } from "react-router-dom";

// reactstrap components
import {
  Badge,
  Media,
  Table,
} from "reactstrap";

function Tables(props) {
   const history = useHistory();
   const handleNavigate = email => {
      history.push(`/${props.culture}-admin/request/${email.id}`);
   }

  return (
    <>
      <Table className="align-items-center" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">{props.content.creator[props.culture]}</th>
            <th scope="col">{props.content.sender[props.culture]}</th>
            <th scope="col">{props.content.statementTitle[props.culture]}</th>
            <th scope="col">{props.content.destination[props.culture]}</th>
            <th scope="col">{props.content.machineNumber[props.culture]}</th>
            <th scope="col">{props.content.serialNumber[props.culture]}</th>
            <th scope="col">{props.content.date[props.culture]}</th>
          </tr>
        </thead>
        <tbody>
          {props.data.map((email) => (
            <tr className="table-row" key={email.id} onClick={() => handleNavigate(email)}>
              <th scope="row">
                <Media className="align-items-center">
                  {/* <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <img
                      alt="..."
                      src={
                        "http://localhost:3000/static/media/bootstrap.bd712487.jpg"
                      }
                    />
                  </a> */}
                  <Media>
                    <span className="mb-0 text-sm">{email.creatorName}</span>
                  </Media>
                </Media>
              </th>
              <td>
                <span className="badge-dot">{email.senderName}</span>
              </td>
              <td>{email.title}</td>
              <td>
                <Badge color="" className="badge-dot">
                  <i className={email.type === 0 ? "bg-success" : "bg-info"} />
                  <span className="type-icon">
                    {email.type === 0
                      ? props.content.personal[props.culture]
                      : props.content.company[props.culture]}
                  </span>
                </Badge>
              </td>
              <td>{email.machineNumber}</td>
              <td>{email.serialNumber}</td>
              <td>{email.createDate?.split("T")[0]}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default Tables;
