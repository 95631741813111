import React, { useState } from "react";
// react router
import {
   BrowserRouter as Router,
   Switch,
   Route,
   Link,
   useRouteMatch,
   useParams
} from "react-router-dom";

// reactstrap components
import {
   Badge,
   DropdownMenu,
   DropdownItem,
   UncontrolledDropdown,
   DropdownToggle,
   Media,
   Progress,
   Table,
   Input,
   UncontrolledTooltip,
   Container
} from "reactstrap";
import ModalPopup from '../components/ModalPopup';

function Tables(props) {

   const [deleteModal, setDeleteModal] = useState(false);
   const [id, setId] = useState('');


   return (
      <>
         <Table className="align-items-center" responsive>
            <thead className="thead-light">
               <tr>
                  <th scope="col">{props.content.employee[props.culture]}</th>
                  <th scope="col">{props.content.branch[props.culture]}</th>
                  <th scope="col">{props.content.action[props.culture]}</th>
               </tr>
            </thead>
            <tbody>
               {props.data.map((employee, index) => (
                  <tr className='' key={employee.employeeId + index}>
                     <th scope="row">
                        <Media className="align-items-center">
                           {/* <a
                              className="avatar rounded-circle"
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                           >
                              <img
                                 alt="..."
                                 src={"http://localhost:3000/static/media/bootstrap.bd712487.jpg"}
                              />
                           </a> */}
                           <Media>
                              <span className="mb-0 text-sm">
                                 {employee.employeeName}
                              </span>
                           </Media>
                        </Media>
                     </th>
                     <td>
                        <span className="badge-dot">
                           {employee.branchName}
                        </span>
                     </td>
                     <td>
                        <div className="d-flex align-items-center justify-content-start">
                           <span className="mr-2">
                              <div id="delete" className="table-action-delete table-action" 
                              onClick={() => {
                                 setId(employee.id);
                                 setDeleteModal(!deleteModal);
                              }}>
                                 <i className="fas fa-trash fa-lg"></i>
                              </div>
                              <UncontrolledTooltip
                                 delay={0}
                                 target="delete"
                              >
                                 {props.content.delete[props.culture]}
                              </UncontrolledTooltip>
                           </span>
                        </div>
                     </td>
                  </tr>
               ))}
               <ModalPopup
                  modal={deleteModal}
                  setModal={setDeleteModal}
                  text={props.content.delete[props.culture]}
                  setId={setId}
                  fn={() => props.handleDelete(id, setDeleteModal)}
                  color='danger'
               >
                  <Container>
                     <h2>{props.content.deletePopup[props.culture]}</h2>
                  </Container>
               </ModalPopup>
            </tbody>
         </Table>
      </>
   );
}

export default Tables