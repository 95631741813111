export default {
    contentLanguage: {
        ar: 'لغة المحتوى',
        en: 'Content Language'
    },
    attachment: {
        ar: "المرفقات",
        en: "Attachments"
    },
    lastEdit: {
        ar: 'أخر تعديل',
        en: 'Last Edit'
    },
    serial: {
        ar: 'الرقم التسلسلي',
        en: 'Serial Number'
    },
    machine: {
        ar: 'الرقم الألي',
        en: 'Machine Number'
    },
    desctiption: {
        ar: 'الوصف',
        en: 'Description'
    },
    decisionDate: {
        ar: 'تاريخ القرار',
        en: 'Decision Date'
    },
    notes: {
        ar: 'ملاحظات',
        en: 'Notes'
    },
    paperCount: {
        ar: 'عدد الاوراق',
        en: 'Paper Count'
    },
    state: {
        ar: 'الحالة',
        en: 'State'
    },
    originality: {
        ar: 'أصل المراسلة',
        en: 'Originality'
    },
    importance: {
        ar: 'الأهمية',
        en: 'Importance'
    },
    security: {
        ar: 'الحماية',
        en: 'Security'
    },
    createdDate: {
        ar: 'تاريخ الإنشاء',
        en: 'Created Date'
    },
    eye: {
        ar: 'تمت القراءة',
        en: 'Mark as Read'
    },
    forword: {
        ar: 'الموافقة وإرسالها',
        en: 'Approve and forward'
    },
    approval: {
        ar: 'إعتماد',
        en: 'Approval'
    },
    decline: {
        ar: 'رفض',
        en: 'Decline'
    },
    seenPopup: {
        ar: 'هل أنت متأكد أنك تريد وضع علامة "مقروءة" على هذه المراسلة ؟',
        en: 'Are you sure you want to mark this correspondence as read ?'
    },
    approvalPopup: {
        ar: 'هل أنت متأكد أنك تريد الموافقة على هذه المراسلة؟',
        en: 'Are you sure you want to approve this correspondece ?'
    },
    declinePopup: {
        ar: 'هل أنت متأكد أنك تريد رفض هذه المراسلات؟',
        en: 'Are you sure you want to decline this correspondence ?'
    },
    forwordPopup: {
        ar: 'هل أنت متأكد أنك تريد الموافقةوارسال هذه المراسلة ؟',
        en: 'Are you sure you want to accept and send this message ?'
    },
    ok: {
        ar: 'موافق',
        en: 'OK'
    }   
}