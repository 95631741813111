export default {
  months: {
    ar: [
      "يناير",
      "فبراير",
      "مارس",
      "إبريل",
      "مايو",
      "يوليو",
      "يونيو",
      "أغسطس",
      "سبتمبر",
      "أكتوبر",
      "نوفمبر",
      "ديسمبر",
    ],
    en: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  },
  title: {
    ar: 'لوحة القيادة',
    en: 'Dashboard'
  },
  sent: {
    ar: 'تم الارسال',
    en: 'Sent'
  },
  totalMessageCreation: {
    ar: 'المراسلات التي تم انشائها',
    en: 'Total created messages'
  },
  totalMessageSent: {
    ar: 'المراسلات التي تم ارسالها',
    en: 'Total sent messages'
  },
  totalMessageApproved: {
    ar: 'المراسلات التي تم الموافقة عليها',
    en: 'Total approved messages'
  },
  created: {
    ar: 'تم إنشائها',
    en: 'Created'
  },
  msgCount: {
    ar: 'عدد المراسلات',
    en: 'Messages count'
  },
  delete: {
    ar: 'حذفط',
    en: 'Delete'
  },
  branches: {
    ar: 'الفروع',
    en: 'Branches'
  },
  externalincominglog: {
    ar: 'الوارد الخارجي',
    en: 'External Incoming'
  },
  internalincominglog: {
    ar: 'الوارد الداخلي',
    en: 'internal incoming'
  },
  marginalizationscs: {
    ar: 'التهميشات',
    en: 'Marginalizations'
  },
  lastMonth: {
    ar: 'منذ أخر شهر',
    en: 'Since last month'
  }
}