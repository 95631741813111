import React, { useState } from "react";
// react router
import {
   BrowserRouter as Router,
   Switch,
   Route,
   Link,
   useRouteMatch,
   useParams
} from "react-router-dom";

// reactstrap components
import {
   Badge,
   DropdownMenu,
   DropdownItem,
   UncontrolledDropdown,
   DropdownToggle,
   Media,
   Progress,
   Table,
   Input,
   UncontrolledTooltip,
   Container
} from "reactstrap";
import ModalPopup from '../components/ModalPopup';
import content from './content';
import getCulture from "utils/getCulture";

function Tables(props) {

   let rout = useRouteMatch();
   let culture = getCulture(rout.url);
   const [editModal, setEditModal] = useState(false);
   const [deleteModal, setDeleteModal] = useState(false);
   const [name, setName] = useState('');
   const [address, setAddress] = useState('');
   const [id, setId] = useState('');
   const [toggle, setToggle] = useState(true);

   const handleUpdate = () => {
      console.log(-1, props.data)
      const rowId = id;
      const rowName = name;
      const rowAddress = address;
      props.updateEmail(rowId, rowName, rowAddress, toggle);
      setEditModal(false);
      setName('');
      setAddress('');
      setId('');
   }
   
   return (
      <>
         <Table className="align-items-center" responsive>
            <thead className="thead-light">
               <tr>
                  <th scope="col">{content.name[culture]}</th>
                  <th scope="col">{content.email[culture]}</th>
                  <th scope="col">{content.type[culture]}</th>
                  <th scope="col">{content.action[culture]}</th>
               </tr>
            </thead>
            <tbody>
               {props.data.map((email, index) => (
                  <tr className='table-row' key={email.id + index}>
                     <th scope="row">
                        <Media className="align-items-center">
                           {/* <a
                              className="avatar rounded-circle"
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                           >
                              <img
                                 alt="..."
                                 src={"http://localhost:3000/static/media/bootstrap.bd712487.jpg"}
                              />
                           </a> */}
                           <Media>
                              <span className="mb-0 text-sm">
                                 {email.name}
                              </span>
                           </Media>
                        </Media>
                     </th>
                     <td>
                        <span className="badge-dot">
                           {email.address}
                        </span>
                     </td>
                     <td>
                        <Badge color="" className="badge-dot">
                           <i className={
                              email.type === 0 ? "bg-success": "bg-info"
                           } />
                           <span className='type-icon'>
                              {email.type === 0 ? content.personal[culture] : content.company[culture]}
                           </span>
                        </Badge>
                     </td>
                     <td>
                        <div className="d-flex align-items-center justify-content-start">
                           <span className="mr-2 mr-3 ml-3">
                              <div id="edit" className="table-action" onClick={() => {
                                 setId(email.id);
                                 setEditModal(!editModal);
                                 setName(email.name);
                                 setToggle(email.type === 0 ? true : false);
                                 setAddress(email.address);
                              }}>
                                 <i className="fas fa-pencil-alt fa-lg blue-hover"></i>
                              </div>
                              <UncontrolledTooltip
                                 delay={0}
                                 target="edit"
                              >
                                 {content.edit[culture]}
                              </UncontrolledTooltip>
                           </span>
                           <span className="mr-2">
                              <div id="delete" className="table-action-delete table-action" 
                              onClick={() => {
                                 props.setId(email.id);
                                 setDeleteModal(!deleteModal);
                              }}>
                                 <i className="fas fa-trash fa-lg"></i>
                              </div>
                              <UncontrolledTooltip
                                 delay={0}
                                 target="delete"
                              >
                                 {content.delete[culture]}
                              </UncontrolledTooltip>
                           </span>
                        </div>
                     </td>
                  </tr>
               ))}
               <ModalPopup
                  modal={editModal}
                  setModal={setEditModal}
                  text={content.ok[culture]}
                  setId={setId}
                  fn={handleUpdate}
               >
                  <div className="modal-body">{content.editPopup[culture]}</div>
                  <Container>
                     <Input
                        placeholder="name"
                        type="text"
                        className="mb-2"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                     />
                     <Input
                        placeholder="address"
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                     />
                     <label className="custom-toggle mb-2 mt-2">
                     <input defaultChecked type="checkbox" 
                        onChange={() => setToggle(!toggle)} 
                        value={toggle}
                        id="typeInput" 
                     />
                     <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off={content.types[1][culture]}
                        data-label-on={content.types[0][culture]}
                     />
                  </label>
                  </Container>
               </ModalPopup>
               <ModalPopup
                  modal={deleteModal}
                  setModal={setDeleteModal}
                  text={content.delete[culture]}
                  setId={setId}
                  fn={() => props.deleteEmail(setDeleteModal)}
                  color='danger'
               >
                  <div className="modal-body">{name}</div>
                  <Container>
                     <h2>{content.deletePopup[culture]}</h2>
                  </Container>
               </ModalPopup>
            </tbody>
         </Table>
      </>
   );
}

export default Tables